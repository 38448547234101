import axios from 'axios';

/**
 * This file contains all of the API calls related specifcally to the Tender
 * Workflow
 * 
 * Admin
 * 
 * Tender
 * GoNoGO
 * Startup
 * Feedback
 * 
 * Approvals
 * Feedback
 * 
 */

/**
 * Get all the tenders to be parsed into a CSV
 * 
 * @param {object} formData 
 * @returns object The data returned from the api request
 */
export async function handleTenderReportDownload(formData) {
  return axios.post('/api/admin/handleTenderReportDownload', formData)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      console.log(err);
    });
}

/**
 * Send a formdata object to the backend to parse a list of csvfles files
 * @param {object} formData 
 * @returns object The data returned from the api request
 */
export async function handleFileUpload(formData) {
  return axios.post('/api/admin/handleHistoricUpload', formData)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      console.log(err);
    });
}

/**
 * Get all the tenders
 * @returns Array[Object]
 */
export function getTenders() {
  return axios.get('/api/tenders')
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      console.log(err);
    });
}

/**
 * Get a specifc tender
 * 
 * @param {String} tenderId The Id of the desired tender
 * @returns The tender object
 */
export function getTender(tenderId) {
  const paramData = {
    params: {
      tenderId: tenderId
    }
  };
  return axios.get('/api/tenders', paramData)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      console.log(err);
    });
}

/**
 * Get all the approvals fro a specfic tender
 * 
 * @param {String} objectId The Id of the object to get the approvals froms
 * @returns An array of approvals (objects)
 */
export function getTenderApprovals(objectId) {
  const paramData = {
    params: {
      objectId: objectId
    }
  };
  return axios.get('/api/approvals', paramData)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      console.log(err);
    });
}

/**
 * Send an approval request of a tender
 * 
 * @param {string} objectId The id of the object being approved
 * @param {string} userId The Id of the user
 * @returns Status of the approval
 */
export function ApproveTenderApprovals(objectId, userId) {
  const paramData = {
    objectId: objectId,
    userId: userId
  };
  return axios.post('/api/approvals', paramData)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      console.log(err);
    });
}

/**
 * Create a tender
 * 
 * @param {object} tenderData The Data of the tender to update
 * @returns Object The created tender
 */
export function createTender(tenderData) {
  return axios.post('/api/tenders', tenderData)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      console.log(err);
    });
}

/**
 * Update a tender
 * 
 * @param {string} tenderId The Id of the tender to be updated
 * @param {object} tenderData The data to be updated
 * @returns The updated tender
 */
export function updateTender(tenderId, tenderData) {
  return axios.post('/api/tenders/' + tenderId, tenderData)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      console.log(err);
    });
}

/**
 * Update a tender
 * 
 * @param {string} tenderId The Id of the tender to be updated
 * @returns The updated tender
 */
export function deleteTender(tenderId) {
  return axios.delete('/api/tenders/' + tenderId)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      console.log(err);
    });
}

/**
 * Get a specifc GoNoGo object
 * 
 * @param {string} goNoGoId The Id of the GoNoGo
 * @returns The GNG data
 */
export function getGoNoGo(goNoGoId) {
  const paramData = {
    params: {
      tenderId: goNoGoId
    }
  };
  return axios.get('/api/gonogo', paramData)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      console.log(err);
    });
}

/**
 * Create a GoNoGo object 
 * 
 * @param {object} goNoGoData The data to create the gonogo with
 * @returns Success and the created GNG
 */
export function createGoNoGo(goNoGoData) {
  return axios.post('/api/gonogo', goNoGoData)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      console.log(err);
    });
}

/**
 * Add feedback for a GNG Object
 * 
 * @param {object} goNoGoFeedbackData The data of the GNG feedback
 * @returns Success and created feedback
 */
export function createGoNoGoFeedback(goNoGoFeedbackData) {
  return axios.post('/api/gonogo/feedback', goNoGoFeedbackData)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      console.log(err);
    });
}

/**
 * Get all tbe feedback for a GNG object
 * 
 * NOTE:
 *      This will get all the feedback for a GNG object
 *      Filtering and parsing it to the correct field still
 *      needs to be done
 * 
 * @param {string} goNoGoId The id of the GNG
 * @returns All the feedback for a GNG object
 */
export function getGoNoGoFeedback(goNoGoId) {
  const paramData = {
    params: {
      goNoGoId: goNoGoId
    }
  };
  return axios.get('/api/gonogo/feedback', paramData)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      console.log(err);
    });
}

/**
 * Get a specifc startup
 * 
 * @param {string} startUpId The ID of the startup
 * @returns Object The statup object
 */
export function getStartUp(startUpId) {
  const paramData = {
    params: {
      tenderId: startUpId
    }
  };
  return axios.get('/api/startup', paramData)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      console.log(err);
    });
}

/**
 * Create a startup object
 * 
 * @param {object} StartUpData The data to create the startup with
 * @returns Success and the object
 */
export function createStartUp(StartUpData) {
  return axios.post('/api/startup', StartUpData)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      console.log(err);
    });
}

/**
 * Get a specifc Data entry object
 * 
 * @param {string} DateEntryId The ID of the Data entry
 * @returns Object The Data entry object
 */
export function getDataEntry(DateEntryId) {
  const paramData = {
    params: {
      tenderId: DateEntryId
    }
  };
  return axios.get('/api/dataentry', paramData)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      console.log(err);
    });
}

/**
 * Create a Data entry object
 * 
 * @param {object} DataEntryData The data to create the data entry obkect with
 * @returns Success and the object
 */
export function createDataEntry(DataEntryData) {
  return axios.post('/api/dataentry', DataEntryData)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      console.log(err);
    });
}
