import React, { useState, useEffect, useReducer } from 'react';
import {
  Box, Typography, FormControl, Button, OutlinedInput,
  FormControlLabel, FormLabel, Select, MenuItem,
  Grid, Dialog, DialogActions,
  DialogContent, DialogTitle, TextField, Avatar,
  ListItem, ListItemAvatar, ListItemButton, ListItemText,
  Switch, Snackbar, Alert
} from '@mui/material';
import { useSearchParams } from 'react-router-dom';

import PersonIcon from '@mui/icons-material/Person';

// API
import { getUser } from '../../api/user/index';
import {
  createGoNoGo, createGoNoGoFeedback, getGoNoGoFeedback,
  createTender, ApproveTenderApprovals
} from '../../api/tender/';

import getFieldHTML from './getFieldHtml';

export default function GoNoGo(props) {
  const tenderFieldsBase = require('../../resources/data/tender.json');
  const gngFieldsBase = require('../../resources/data/gonogo.json');

  const [loaded, setLoaded] = useState(false);

  const [tenderFields, setTenderFields] = useState([]);
  const [gngFields, setGngFields] = useState([]);
  const [submissionStatus, setSubmissionStatus] = useState('');
  const [createMode, setCreateMode] = useState(false);

  const [open, setOpen] = React.useState(false);
  const [reviewField, setReviewField] = useState({});
  const [reviewFeedback, setReviewFeedback] = useState([]);
  const [userId, setUserId] = useState('');
  const [feedback, setFeedback] = useState([]);
  const [feedbackNote, setFeedbackNote] = useState('');

  const [sumFields, setSumfields] = useState({});
  const [gngVal, setgngVal] = useState('');
  const [gngPer, setgngPer] = useState('');

  const [reviewMode, setReviewMode] = useState(false);
  const [searchparams] = useSearchParams();
  const [canApprove, setCanApprove] = useState(false);

  const [flashOpen, setFlashOpen] = useState(false);
  const [flashType, setFlashType] = useState('success');
  const [flashMessage, setFlashMessage] = useState('');

  const [tenderFormInput, setTenderFormInput] = useReducer(
    (state, newState) => {
      const combinedState = { ...state, ...newState };
      if (createMode) {
        localStorage.setItem('tender_draft', JSON.stringify(combinedState));
      }
      return (combinedState);
    },
  );
  const [gngFormInput, setGngFormInput] = useReducer(
    (state, newState) => {
      const combinedState = { ...state, ...newState };
      if (createMode) {
        localStorage.setItem('gng_draft', JSON.stringify(combinedState));
      }
      return (combinedState);
    },
  );

  useEffect(() => {
    setLoaded(false);
    loadGoNoGo();
    setLoaded(true);
  }, [props])

  /**
   * Handle the the review mode changed
   * 
   * @param {object} event 
   */
  function handleReviewChange(event) {
    setReviewMode(event.target.checked);
  }

  /**
   * Handle an input change and update the field value
   * 
   * @param {DOMEvent} event The onChange event
   */
  function handleInput(event) {
    const name = event.target.name;
    const key = event.target.value;
    updateFieldValue(name, key);
  }

  /**
   * Handle the creation of the review field
   * 
   * NOTE:
   *    This is reset on each click to clear the input
   * 
   * @param {DOMEvent} event The onChange event
   */
  function handleReviewInput(event) {
    const key = event.target.value;
    let value = 0;
    reviewField.proposedValue = { 'key': key, 'value': value };
    setReviewField(reviewField);
  }

  /**
   *  Update the status of the GoNoGo submission
   * 
   * @param {DOMEvent} event The onChange event
   */
  function handleStatus(event) {
    const key = event.target.value;
    setSubmissionStatus(key);
  }

  /**
   * Set a mandatory field to have an error if it doesn't pass
   * validation
   * 
   * @param {object} formField The field to check
   * @returns The modified field
   */
    function setSubmittedMandatoryFields(formField) {
      formField.isError = true;
      formField.errorText = 'Mandatory';
      return formField;
    }

  /**
   * Check if any of the mandatory fields have been filled out
   * 
   * @returns Whether all fields that have been made mandatory have been filled in
   */
  function checkSubmissionFields() {
    let submissionValid = true;
    if (! tenderFormInput || ! tenderFormInput['client_code'] || tenderFormInput['client_code']['key'] === '') {
      submissionValid = false;
      tenderFields.forEach(tenderField => {
        if (tenderField.type === 'group') {
          tenderField.values.forEach(tenderField => {
            if (tenderField.id === 'client_code') {
              tenderField = setSubmittedMandatoryFields(tenderField);
            }
          });
        } else {
          if (tenderField.id === 'client_code') {
            tenderField = setSubmittedMandatoryFields(tenderField);
          }
        }
      });
      setTenderFields(previousFields => tenderFields);
    }

    return submissionValid;
  }

  /**
   * Format the form information and submit to the backend
   * @param {object} event 
   */
  async function handleSubmit(event) {
    event.preventDefault();

    let tenderId = props.tenderInformation.id;
    
    const validSubmission = checkSubmissionFields();

    if (! validSubmission) {
      setFlashType('error');
      setFlashMessage('Please fill in all mandatory fields');
      setFlashOpen(true);
      return;
    }
    if (! props.tenderInformation.id || props.tenderInformation.status === 'draft') {
      localStorage.removeItem('has_draft');
      localStorage.removeItem('gng_draft');
      localStorage.removeItem('tender_draft');
      
      const tenderData = { tenderFormInput };
      const tenderResult = await createTender(tenderData);
      tenderId = tenderResult['data']['id'];
    } else {
      props.tenderSubmit();
    }

    const gngData = {
      'gngFormInput': {
        'tender_id': tenderId,
        'status': submissionStatus,
        'fields': gngFormInput
      }
    };

    createGoNoGo(gngData).then((res) => {
      if (createMode) {
        window.location.assign('/home/tenders/' + tenderId);
      } else {
        window.location.reload(false);
      }
    });
  }

  /**
   * Load the review field into the model and get all the feedback
   * needed to update the object
   * 
   * @param {Object} field The field that is being reviewed
   */
  function handleClickOpen(field) {
    setReviewField(field);
    let ids = reviewFeedback.map(a => a.id);
    feedback.forEach(element => {
      if (element.field === field.id && ! ids.includes(element.id)) {
        reviewFeedback.push(element);
      }
    });
    setReviewFeedback(reviewFeedback);
    setOpen(true);
  }

  /**
   * Handle the closing of the review modal
   */
  function handleClose() {
    setOpen(false);
  }

  /**
   * Update the field value in the form input
   * 
   * @param {string} name The name of the field
   * @param {string} key The key of the field
   */
  function updateFieldValue(name, key) {
    let value = 0;

    if (createMode) {
      localStorage.setItem('has_draft', true);
    }

    // Check fields with their values for the use in the calculations
    gngFieldsBase['data'].forEach(field => {
      if (field.id === name) {
        if (field.values) {
          field.values.forEach(fieldValues => {
            if (fieldValues.name === key) {
              value = fieldValues.value;
            }
          })
        }
        setGngFormInput({ [name]: { 'key': key, 'value': value } });
        updateSumFields(name, value);
        return;
      }
    });
    tenderFieldsBase['data'].forEach(field => {
      if (field.id === name) {
        if (field.values) {
          field.values.forEach(fieldValues => {
            if (fieldValues.name === key) {
              value = fieldValues.value;
            }
          })
        }
        setTenderFormInput({ [name]: { 'key': key, 'value': value } });
        return;
      }
    });
  }

  /**
   * Calculate the Tender score based on a given input
   * 
   * @param {string} name The name of the field
   * @param {number} value The value of the field
   */
  function updateSumFields(name, value) {
    sumFields[name] = value;
    setSumfields(sumFields);
    if (
      name === 'sandbox-yn' ||
      name === 'sandbox-how' ||
      name === 'sandbox-val' ||
      name === 'sandbox-location' ||
      name === 'client-type' ||
      name === 'project-margin' ||
      name === 'project-doc-quality' ||
      name === 'competition-num' ||
      name === 'competition-weight'
    ) {
      const sandboxYn =
        parseFloat(sumFields['sandbox-yn'] || '') || 0;
      const sandboxHow =
        parseFloat(sumFields['sandbox-how'] || '') || 0;
      const sandboxVal =
        parseFloat(sumFields['sandbox-val'] || '') || 0;
      const sandboxLocation =
        parseFloat(sumFields['sandbox-location'] || '') || 0;
      const clientType =
        parseFloat(sumFields['client-type'] || '') || 0;
      const projectMargin =
        parseFloat(sumFields['project-margin'] || '') || 0;
      const projectDocQuality =
        parseFloat(sumFields['project-doc-quality'] || '') || 0;
      const competitionNum =
        parseFloat(sumFields['competition-num'] || '') || 0;
      const competitionWeight =
        parseFloat(sumFields['competition-weight'] || '') || 0;

      if (sandboxYn === 1) {
        setgngVal('Project Doesn\'t Fit Sandbox');
        setgngPer('Nil %');
      } else {
        const total = (sandboxYn) + (sandboxHow) + (sandboxVal)
          + (sandboxLocation) + (clientType) + (projectMargin)
          + (projectDocQuality) + (competitionNum) + (competitionWeight);

        const totalPercentage = (total / 46.00) * 100.00;
        setgngVal(total + '/46');
        setgngPer(totalPercentage.toFixed(2) + '%');
      }
    }
  }

  /**
   * Add feedback to the DB object with the feedback given and
   * the proposed changed
   * 
   * @param {string} feedback The feedback given for the field
   * @param {string} proposedChange The proposed change to the field
   */
  async function addFeedback(feedback, proposedChange) {
    const feedbackData = {
      'object_id': props.goNoGoForm.id,
      'object': 'gng',
      'proposed_changes': proposedChange.proposedValue,
      'note': feedback,
      'field': proposedChange
    };
    await createGoNoGoFeedback(feedbackData);

    // Retrieve the feedback again and set it to update the form
    getGoNoGoFeedback(props.goNoGoForm.id).then((res) => {
      setFeedback(res.data);
    });

    setOpen(false);
  }

  /**
   * Send an approval of the tender
   */
  async function approveSubmission() {
    ApproveTenderApprovals(props.goNoGoForm.id, userId);
  }

  /**
   * Load in all the tender and Go-no-go information
   * and fill out all the fields
   */
  function loadGoNoGo() {
    const mode = searchparams.get('review');
    if (mode) {
      setReviewMode(true);
    }

    // Deep copy the base attributes
    const gngFieldsBaseData = JSON.parse(JSON.stringify(gngFieldsBase))['data'];
    const tenderFieldsBaseData = JSON.parse(JSON.stringify(tenderFieldsBase))['data'];

    if (props.goNoGoForm.fields && props.tenderInformation.id) {
      const gngSubmittedFields = JSON.parse(props.goNoGoForm.fields);

      tenderFieldsBaseData.forEach(element => {        
        if (Object.keys(props.tenderInformation).includes(element.id)) {
          updateFieldValue(element.id, props.tenderInformation[element.id]);
          element.selectedValue = props.tenderInformation[element.id];
        }
      });
      gngFieldsBaseData.forEach(element => {
        if (Object.keys(gngSubmittedFields).includes(element.id)) {
          updateFieldValue(element.id, gngSubmittedFields[element.id]['key']);
          element.selectedValue = gngSubmittedFields[element.id];
        }
      });
      setTenderFields(tenderFieldsBaseData);
      setGngFields(gngFieldsBaseData);
      setSubmissionStatus(props.goNoGoForm.status);

      getGoNoGoFeedback(props.goNoGoForm.id).then((res) => {
        setFeedback(res.data);
      });
    } else {
      if (! props.tenderInformation || props.tenderInformation.status === 'draft') {
        setCreateMode(true);
      }
      if (localStorage.getItem('has_draft')) {
        let tenderDraft = localStorage.getItem('tender_draft');
        let gngDraft = localStorage.getItem('gng_draft');

        if (tenderDraft && tenderDraft !== 'undefined') {
          tenderDraft = JSON.parse(tenderDraft);
          tenderFieldsBaseData.forEach(element => {
            if (Object.keys(tenderDraft).includes(element.id)) {
              updateFieldValue(element.id, tenderDraft[element.id]['key']);
              element.selectedValue = tenderDraft[element.id];
            }
          });
        }

        if (gngDraft && gngDraft !== 'undefined') {
          gngDraft = JSON.parse(gngDraft);
          gngFieldsBaseData.forEach(element => {
            if (Object.keys(gngDraft).includes(element.id)) {
              updateFieldValue(element.id, gngDraft[element.id]['key']);
              element.selectedValue = gngDraft[element.id];
            }
          });
        }
      }
      setTenderFields(tenderFieldsBaseData);
      setGngFields(gngFieldsBaseData);
    }

    getUser().then((res) => {
      setUserId(res.user.id);
      let canApprove = res.user.approver && props.goNoGoForm.status === 'approval';
      setCanApprove(canApprove);
    });
  }

  /**
   * Render all of the GoNoGo fields into HTML
   * 
   * @returns Array[HTMLObject] THe rendering of all the fields
   */
  function renderGNGItems() {
    return gngFields.map(field =>
      getFieldHTML(field, handleInput, null, null, reviewMode, handleClickOpen)
    );
  }

  /**
   * Render all of the Tender fields into HTML
   * 
   * @returns Array[HTMLObject] THe rendering of all the fields
   */
  function renderTenderItems() {
    return tenderFields.map(field =>
      getFieldHTML(field, handleInput)
    );
  }

  return (
    <>
      <form onSubmit={handleSubmit} className={"step-form".concat(loaded ? '' : ' hidden')}>
        {(createMode  || props.tenderInformation.status === 'draft') &&
          <Box>
            <Typography className="secondary-title text">Tender Information</Typography>
            <Grid container spacing={2} className="field-grid">
              {renderTenderItems()}
            </Grid>
          </Box>

        }
        {(! createMode && props.tenderInformation.status !== 'draft') &&
          <Box className="review-switch">
            <FormControlLabel control={
              <Switch
                checked={reviewMode}
                onChange={handleReviewChange}
              />
            } label="Review Mode" />

          </Box>
        }
        <Dialog open={open} onClose={handleClose}>
          <DialogTitle className="feedback-dialog">Feedback: {reviewField.label}</DialogTitle>
          <DialogContent>
            {reviewFeedback.length === 0 &&
              <Box className="no-feedback">
                <p>No Feedback Yet</p>
              </Box>
            }
            {reviewFeedback.map(feedback => {
              return <ListItem disableGutters>
                <ListItemButton key={feedback}>
                  <ListItemAvatar className="feedback-avatar">
                    <Avatar>
                      <PersonIcon />
                    </Avatar>
                    <p className="feedback-user">{feedback['User.email']}</p>
                  </ListItemAvatar>
                  <Box>
                    <ListItemText primary={feedback.note} />
                    {feedback.proposed_changes &&
                      <ListItemText
                        secondary={"Proposed Change: " + JSON.parse(feedback.proposed_changes).key}
                      />
                    }
                    {! feedback.note &&
                      <ListItemText
                        secondary={"Changed from: " + feedback.previous_value + " to: " + feedback.new_value}
                      />
                    }
                  </Box>

                </ListItemButton>
              </ListItem>
            })}
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <label>
                Proposed Changes:
              </label>
              <label>
                Note:
              </label>
              <TextField
                autoFocus
                margin="dense"
                id="name"
                label="Feedback"
                type="text"
                fullWidth
                multiline={true}
                required
                value={feedbackNote}
                onChange={(event) => {
                  setFeedbackNote(event.target.value)
                }}
              />
              <label>
                New Value:
              </label>
              {getFieldHTML(reviewField, handleReviewInput, null, null, false)}
            </Box>

          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button onClick={() => addFeedback(feedbackNote, reviewField)}>Add Feedback</Button>
          </DialogActions>
        </Dialog>
        <Typography className="secondary-title text">Go No Go Information</Typography>

        <Grid container spacing={2} className="field-grid">
          {renderGNGItems()}
        </Grid>
        <Box className="summary">
          <Typography className="secondary-title text">Go No Go Summary</Typography>
          <Box className="summary-fields">
            <FormControl name="go-no-go-val">
              <OutlinedInput
                label="Final Score"
                readOnly
                value={gngVal}
              />
            </FormControl>
            <FormControl name="go-no-go-percentage">
              <OutlinedInput
                label="Final Percentage"
                readOnly
                value={gngPer}
              />
            </FormControl>
          </Box>
          <Box className="summary-score">
            <span>Tender Score card to be assessed and addressed without bias and with no emotion</span>
            <div className="summary-score-blurb">
              <div className="summary-score-left">
                <span>More than 80%</span>
                <span>60% to 89%</span>
                <span>Less than 60%</span>
              </div>
              <div className="summary-score-right">
                <span>Project must be considered.</span>
                <span>Project should be considered by SLT with consideration of YES / NO criteria</span>
                <span>Project should not be considered</span>
              </div>
            </div>
          </Box>
        </Box>
        <Box className="step-status">
          <FormControl className="form-label status-label">
            <FormLabel id="status">Go-No-Go Status</FormLabel>
            <Select
              labelId="status"
              name="status"
              onChange={handleStatus}
              value={submissionStatus}
            >
              <MenuItem value="na">
                <em>NA</em>
              </MenuItem>
              <MenuItem value="draft">
                <em>Draft</em>
              </MenuItem>
              <MenuItem value="wip">
                <em>WIP</em>
              </MenuItem>
              <MenuItem value="approval">
                <em>Waiting for approval</em>
              </MenuItem>
              <MenuItem value="completed">
                <em>Completed</em>
              </MenuItem>
            </Select>
          </FormControl>
        </Box>
        <Box className="step-submission">
          <Button
            type="submit"
            variant="contained"
            color="primary"
            className="btn-primary"
          >
            Submit
          </Button>
          {canApprove &&
            <Button
              variant="contained"
              color="primary"
              className="btn-primary btn-right"
              onClick={approveSubmission}
            >
              Approve
            </Button>
          }
        </Box>
      </form>
      <Snackbar open={flashOpen} autoHideDuration={4000} onClose={() => setFlashOpen(false)}>
        <Alert className="flash" onClose={() => setFlashOpen(false)} severity={flashType}>
          {flashMessage}
        </Alert>
      </Snackbar>
    </>
  )
}
