import { useState } from 'react';
import { useLocation } from 'react-router-dom';

import { Box, Typography, Divider, Button, TextField } from '@mui/material';
import axios from 'axios';

// Asset Imports:
import IconElement from '../../resources/elements/Building-Innovation.svg';
import ICMLogo from '../../resources/icons/ICM_Logo_stacked.svg';
import mircosoftLoginLogo from '../../resources/elements/MS_SignIn.png';

export default function Login() {

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  let location = useLocation();
  if (location.state !== null) {
    localStorage.setItem('origin_url', location.state.origin_url);
  }

  /**
   * Login using the local method in passport (username and pwd)
   * (From the backend)
   */
  const login = async () => {
    await axios.post('/auth/login', {
      'username': email,
      'password': password
    }).then((res) => {
      if (res.status === 200 && window) {
        window.location.href = '/home';
      }
    }).catch((err) => {
      window.location.href = '/login/failed';
    });
  }

  /**
   * Login using the microsoft method in passport
   * (From the backend)
   */
  const mircosoftLogin = async () => {
    // Get the MS Auth request
    window.location.href = `${process.env.REACT_APP_BACKEND_URL}/auth/microsoft`;
  }

  return (
    <div>
      <div className="login-background">
        <Box className="m-auto flex flex-col lg:flex-row">
          <Box className="bg-background-secondary rounded-3xl max-w-xs lg:max-w-loginBox lg:p-3 flex flex-col justify-center items-center drop-shadow-md">
            <img className="h-36 py-8 mx-4" src={ICMLogo} alt="ICM Construction Logo" />
            <Divider className="px-32 lg:px-48" />
            <Typography className="title text my-4">Reporting Portal</Typography>
            <Typography className="text px-12">Click the below 'Login' button to access your personalized account.</Typography>
            <TextField className="input-field" id="standard-basic" label="Email" variant="standard"
              value={email}
              onChange={(event) => {
                setEmail(event.target.value);
              }} />
            <TextField className="input-field" id="standard-basic" label="Password" variant="standard"
              value={password}
              onChange={(event) => {
                setPassword(event.target.value);
              }} />
            <Button
              onClick={login}
              className="input-field btn-primary" variant="contained">
              Login
            </Button>
            <Button
              onClick={mircosoftLogin}
              className="microsoft-login" variant="text">
              <img src={mircosoftLoginLogo} />
            </Button>
            <Box className="flex flex-col lg:flex-row">
              <Typography className="pt-4 lg:py-7 italic text-center lg:text-justify text-desktop-login-secondary text-primary">For enquires please contact:</Typography>
              <Typography className="mb-6 pb-6 lg:py-7 italic text-center lg:text-justify font-semibold text-desktop-login-secondary text-primary">info@icmco.com.au</Typography>
            </Box>
          </Box>
          <Box className="side-logo">
            <div>
              <img className="side-logo" src={IconElement} alt="Business Innovation Logo" />
            </div>
          </Box>
        </Box>
      </div>
    </div>
  );
}