import React, { useState, useEffect } from 'react';
import {
  Box, Button, Typography, FormControl, FormLabel,
  TextField, Select, MenuItem, ListItemText, Checkbox,
  Switch, Stack, Divider, IconButton, Tooltip as MuiToolTip
} from '@mui/material';
import {
  PieChart, Pie, BarChart, Bar, XAxis, YAxis,
  CartesianGrid, Tooltip, Legend, LabelList
} from 'recharts';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'

import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

export default function TenderChart(props) {
  const RADIAN = Math.PI / 180;

  const dateToolTip = `
        Ensure the tenders have a valid start or
        due date for them to be seen in this graph
    `;

  const [selectedSectors, setselectedSectors] = useState(['All']);
  const [selectedCategories, setselectedCategories] = useState(['All']);
  const [selectMetrics, setSelectedMetrics] = useState(['All']);
  const [chartType, setChartType] = useState('bar');
  const [chartSwitch, setChartSwitch] = useState(true);
  const [showBreakdown, setShowBreakdown] = useState(false);
  const [dates, setDates] = useState({});
  const [dateType, setDateType] = useState('Due');

  const [parentData, setParentData] = useState({});
  const [parentDataMetrics, setParentDataMetrics] = useState([]);
  const [parentDataSectors, setParentDataSectors] = useState(new Set());
  const [parentDataCategory, setParentDataCategory] = useState(new Set());

  const [baseData, setBaseData] = useState([]);
  const [pieDataLvl2, setPieDataLvl2] = useState([]);
  const [pieDataLvl3, setPieDataLvl3] = useState([]);
  const [pieDataLvl4, setPieDataLvl4] = useState([]);

  const [metricMetadata, setMetricMetadata] = useState([
    { name: 'lost', colour: '#d50b00' },
    { name: 'won', colour: '#47dc00' },
    { name: 'submitted', colour: '#0064ff' },
    { name: 'wip', colour: '#ff9b00' },
    { name: 'withdrawn', colour: '#7700b2' }
  ]);

  // ----- Use Effect Handler for state changes
  useEffect(() => {
    parseTenders();
  }, [
    selectedSectors, selectedCategories,
    selectMetrics, chartType,
    dates, dateType
  ])

  // ----- Handle State changes
  /**
   * Handle a filtering of a Sector
   * Can either be specfic sector or 'All'
   * @param {DOMEvent} event 
   */
  function handleSectorChange(event) {
    let { target: { value } } = event;
    if (value.includes('All')) {
      if (value.length > 1) {
        const index = value.indexOf('All');
        if (index > -1 && index < value.length - 1) { // only splice array when item is found
          value.splice(index, 1); // 2nd parameter means remove one item only
        } else {
          value = ['All'];
        }
      } else {
        value = ['All'];
      }
    }
    setselectedSectors(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
  }

  /**
   * Handle a filtering of a category
   * Can either be specfic category or 'All'
   * @param {DOMEvent} event 
   */
  function handleCategoryChange(event) {
    let { target: { value } } = event;
    if (value.includes('All')) {
      if (value.length > 1) {
        const index = value.indexOf('All');
        if (index > -1 && index < value.length - 1) { // only splice array when item is found
          value.splice(index, 1); // 2nd parameter means remove one item only
        } else {
          value = ['All'];
        }
      } else {
        value = ['All'];
      }
    }
    setselectedCategories(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
  }

  /**
   * Handle a filtering of a metric
   * Can either be specfic metric or 'All'
   * @param {DOMEvent} event 
   */
  function handleMetricChange(event) {
    let { target: { value } } = event;
    if (value.includes('All')) {
      if (value.length > 1) {
        const index = value.indexOf('All');
        if (index > -1 && index < value.length - 1) { // only splice array when item is found
          value.splice(index, 1); // 2nd parameter means remove one item only
        } else {
          value = ['All'];
        }
      } else {
        value = ['All'];
      }
    }
    setSelectedMetrics(
      typeof value === 'string' ? value.split(',') : value,
    );
  }

  /**
   * Handle whether to show a Pie or a Bar chart
   * @param {DOMEvent} event 
   */
  function handleChartSwitch(event) {
    let value = event.target.checked;
    if (value) {
      setChartType('bar');
    } else {
      setChartType('pie');
    }
    setChartSwitch(value);
  }

  /**
   * Handle whether to show a Sector focused graph or a Category focused graph
   * @param {DOMEvent} event The event object of the switch
   */
  function handleBreakdownSwitch(event) {
    let value = event.target.checked;
    setShowBreakdown(value);
  }

  /**
   * Handle the date type being changed (either from Start or Due)
   * @param {DOMEvent} event The event object of the switch
   */
  function handleDateTypeChange(event) {
    let { target: { value } } = event;
    setDateType(value);
  }

  /**
   * Clear the dates of the date filters
   */
  function clearDates() {
    setDates({});
    setDateType('Due');
  }

  // ----- Formatting data to be Graph friendly
  /**
   * Parse the ParentData object and create the base level of data to be used
   * for both the pie chart and the bar charts
   * 
   * @param {Object} newParentData The new parent data to be used to get the values to 
   *  render
   * 
   * Format of the rendering of the data goes
   *    Metric -> Category
   * 
   * Generate an object in the format
   *  <sector>: {
   *      'calculated': { The Total value of all the relevant metrics }
   *      'categories': {
   *          <category>: { The Metrics of the specifc category }
   *      }
   * }
   * 
   */
  function rerenderData(newParentData) {
    for (const [_, dataPoint] of Object.entries(newParentData)) {
      let caclulatedMetrics = {
        'metrics': {
          'all': 0
        }
      };

      if (selectMetrics.includes('All')) {
        // ALL METRIC
        parentDataMetrics.forEach(metric => {
          let metricValue = 0;

          if (selectedCategories.includes('All')) {
            // ALL METRIC + ALL CATEGORY
            parentDataCategory.forEach(category => {
              if (dataPoint['categories'][category] && dataPoint['categories'][category]['metrics'][metric['name']]) {
                metricValue += dataPoint['categories'][category]['metrics'][metric['name']];
              }
            });
          } else {
            // ALL METRIC + SELECT CATEGORY
            selectedCategories.forEach((category) => {
              if (dataPoint['categories'][category] && dataPoint['categories'][category]['metrics'][metric['name']]) {
                metricValue += dataPoint['categories'][category]['metrics'][metric['name']];
              }
            });
          }
          caclulatedMetrics['metrics'][metric['name']] = metricValue;
          caclulatedMetrics['metrics']['all'] += metricValue;
        });

      } else {
        selectMetrics.forEach(metric => {
          // SELECT METRIC
          let metricValue = 0;
          if (selectedCategories.includes('All')) {
            // SELECT METRIC + ALL CATEGORY
            parentDataCategory.forEach(category => {
              if (dataPoint['categories'][category] && dataPoint['categories'][category]['metrics'][metric]) {
                metricValue += dataPoint['categories'][category]['metrics'][metric];
              }
            });
          } else {
            // SELECT METRIC + SELECT CATEGORY
            selectedCategories.forEach((category) => {
              if (dataPoint['categories'][category] && dataPoint['categories'][category]['metrics'][metric]) {
                metricValue += dataPoint['categories'][category]['metrics'][metric];
              }
            });
          }
          caclulatedMetrics['metrics'][metric] = metricValue;
          caclulatedMetrics['metrics']['all'] += metricValue;
        });
      }
      dataPoint['calculated'] = caclulatedMetrics;
    }
    setParentData(newParentData);
    let mapLvl1 = [];
    Object.entries(newParentData).forEach(function (data) {
      if (selectedSectors.indexOf(data[0]) > -1 || selectedSectors.indexOf('All') > -1) {
        mapLvl1.push(data[1]);
      }
    });
    setBaseData(mapLvl1);

    if (chartType === 'pie') {
      generatePieData(newParentData);
    }
  }

  /**
   * Parse the Parent data object and create an Array that is compatible
   * with the Pie Chart 
   * Generate the levels of Pie data that correspond with the different
   * Pie charts / rings of pie charts
   * 
   * This function covers the inner and outer ring of the category breakdown graph
   * and the outer ring of the sector graph. The inner ring is set from BaseData
   * 
   * @param {Object} newParentData The new parent data to be used to get the values to 
   *  render
   *      
   */
  function generatePieData(newParentData) {
    /**
     * Outer ring of the Sector Pie chart
     * (not showing breakdown)
     * 
     * [
     *    {
     *      'name': <Metric Name>,
     *      'value': <Value of Metric>
     *      'sector' <Sector Name>
     *    }
     * ]
     */ 
    const mapLvl2 = [].concat(...Object.entries(newParentData).map((data) => {
      if (selectedSectors.indexOf(data[0]) > -1 || selectedSectors.indexOf('All') > -1) {
        const wholeMetData = Array.from(parentDataMetrics).map((metData) => {
          const returnData = {
            'name': metData['name'],
            'value': data[1]['calculated']['metrics'][metData['name']],
            'sector': data[0]
          };
          return returnData;
        })
        return wholeMetData;
      }
    }));

    /**
     * Inner ring of the Category Pie chart
     * (showing breakdown)
     * 
     * [
     *    {
     *      'name': <Category Name>,
     *      'value': <Total Value of Category>
     *    }
     * ]
     */ 
    const mapLvl3 = [].concat(...Object.entries(newParentData).map((data) => {
      if (selectedSectors.indexOf(data[0]) > -1 || selectedSectors.indexOf('All') > -1) {
        try {
          const sectorCategories = data[1]['categories'];
          return [].concat(...Object.entries(sectorCategories).map((catData) => {
            if (catData[0] !== 'calculated' && catData[0] !== 'name' && (
              selectedCategories.includes(catData[0]) || selectedCategories.includes('All'))) {
              let totalMetValue = 0;
              try {
                Array.from(parentDataMetrics).map((metData) => {
                  if (selectMetrics.indexOf(metData['name']) > -1 || selectMetrics.indexOf('All') > -1) {
                    totalMetValue += catData[1]['metrics'][metData['name']] ?? 0;
                  }
                })
                const returnData = {
                  'name': catData[0],
                  'value': totalMetValue
                };
                return returnData;
              } catch (error) {
                console.log(error);
              }
            }
            return [];
          }))
        } catch (error) {
          console.log(error);
        }
      }
    }));

    /**
     * Outer ring of the Category Pie chart 
     * (with breakdown)
     * 
     * [
     *    {
     *      'name': <Metric Name>,
     *      'value': <Value of Metric>
     *    }
     * ]
     */ 
    const mapLvl4 = [].concat(...Object.entries(newParentData).map((data) => {
      if (selectedSectors.indexOf(data[0]) > -1 || selectedSectors.indexOf('All') > -1) {
        try {
          const sectorCategories = data[1]['categories'];
          return [].concat(...Object.entries(sectorCategories).map((catData) => {
            if (catData[0] !== 'calculated' && catData[0] !== 'name' && (
              selectedCategories.includes(catData[0]) || selectedCategories.includes('All'))) {
              try {
                const wholeMetData = Array.from(parentDataMetrics).map((metData) => {
                  if (selectMetrics.indexOf(metData['name']) > -1 || selectMetrics.indexOf('All') > -1) {
                    const returnData = {
                      'name': metData['name'],
                      'value': catData[1]['metrics'][metData['name']] ?? 0
                    };
                    return returnData;
                  }
                })
                return wholeMetData;
              } catch (error) {
                console.log(error);
              }
            }
            return [];
          }))
        } catch (error) {
          console.log(error);
        }
      }
    }));

    setPieDataLvl2(mapLvl2);
    setPieDataLvl3(mapLvl3);
    setPieDataLvl4(mapLvl4);
  }

  /**
   * Parse the Tender list into a format that be used to calculate the Chart friendly values
   * The function is used to make the changes in filtering:
   *  - Sector Changes
   *  - Category Changes
   *  - Metric Changes
   *  - Chart type changes (Pie, Bar)
   *  - Dates
   *  - Date Types
   */
  function parseTenders() {
    let dataMetrics = new Set();
    let newParentData = {};
    props.tenderList.forEach(tender => {
      if (tender.status === 'draft') {
        return;
      }
      const dataEntryFields = JSON.parse(tender['DataEntry.fields']);
      const goNoGoFields = JSON.parse(tender['GoNoGo.fields'])

      const tenderStatus = tender['status'];

      // Check if any of the necessary values are not valid
      if (! tenderStatus || ! dataEntryFields || ! goNoGoFields) {
        return;
      }

      let tenderDate = '';
      if (dateType === 'Start') {
        tenderDate = tender['start_date'];
      } else {
        tenderDate = tender['due_date'];
      }

      // If the dates do not match any of the conditions, exclude it from
      // the total calculation
      let excludeCalc = false;
      if (! tenderDate) {
        excludeCalc = true;
      }
      if (dates['start_date'] && ! dates['end_date'] && tenderDate < dates['start_date']) {
        excludeCalc = true;
      } else if (! dates['start_date'] && dates['end_date'] && tenderDate > dates['end_date']) {
        excludeCalc = true;
      } else if (dates['start_date'] && dates['end_date'] &&
                  (tenderDate > dates['end_date'] || tenderDate < dates['start_date'])) {
        excludeCalc = true;
      }

      if (! goNoGoFields) {
        return;
      }

      let sector = 'unattributed'
      let category = 'unattributed'
      if (goNoGoFields['sector']) {
        sector = goNoGoFields['sector']['value'];
      }

      if (! newParentData[sector]) {
        newParentData[sector] = {
          'calculated': {
            'metrics': {}
          },
          'name': sector,
          'categories': {}
        };
      }

      if (goNoGoFields['category']) {
        category = goNoGoFields['category']['value'];
      }

      if (! newParentData[sector]['categories'][category]) {
        newParentData[sector]['categories'][category] = {
          'name': category,
          'metrics': {}
        };
      }

      parentDataSectors.add(sector);
      parentDataCategory.add(category);
      dataMetrics.add(tenderStatus);

      if (! newParentData[sector]) {
        newParentData[sector] = {
          'categories': {},
          'calculated': {
            'name': 'All',
            'metrics': {
              'won': 0,
              'lost': 0,
              'submitted': 0,
              'all': 0
            }
          },
        };
      }

      let finalValue = 0;
      if (dataEntryFields['resubmitted-value'] && dataEntryFields['resubmitted-value']['key'] != '') {
        finalValue = dataEntryFields['resubmitted-value']['key'].replace('$', '');
      } else if (dataEntryFields['submitted-value']) {
        finalValue = dataEntryFields['submitted-value']['key'].replace('$', '');
      }
      if (excludeCalc || ! finalValue) {
        finalValue = '0';
      }
      finalValue = finalValue.replaceAll(',', '');
      
      if (newParentData[sector]['categories'][category]['metrics'][tenderStatus]) {
        newParentData[sector]['categories'][category]['metrics'][tenderStatus] += parseFloat(finalValue);
      } else {
        newParentData[sector]['categories'][category]['metrics'][tenderStatus] = parseFloat(finalValue);
      }

      if (newParentData[sector]['calculated']['metrics'][tenderStatus]) {
        newParentData[sector]['calculated']['metrics'][tenderStatus] += parseFloat(finalValue);
      } else {
        newParentData[sector]['calculated']['metrics'][tenderStatus] = parseFloat(finalValue);
      }
    });

    // Get all the metrics, and assign them 
    let seen = new Set();
    Array.from(parentDataMetrics).forEach(metric => {
      seen.add(metric['name'].toLowerCase())
    })
    Array.from(dataMetrics).forEach(metric => {
      if (seen.has(metric)) {
        return;
      }
      let color = getColor();
      const metadataColor = metricMetadata.filter(
        (metricMetadataObject) => metricMetadataObject['name'] === metric)[0];
      if (metadataColor) {
        color = metadataColor['colour'];
      } else {
        setMetricMetadata((prevState) => {
          prevState.push({
            'name': metric,
            'colour': color
          });
          return prevState;
        });
      }
      parentDataMetrics.push({
        'name': metric,
        'colour': color
      });
    });
    setParentDataMetrics(parentDataMetrics);
    setParentDataCategory(parentDataCategory);
    setParentDataSectors(parentDataSectors);

    rerenderData(newParentData);
  }


  /**
   * Capatalise a string, turning the first letter to uppercase
   * 
   * @param {string} string The string to format
   * @returns The string with capatlisation
   */
  function capitalizeFirstLetter(string) {
    if (string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    }
  }

  /**
   * Generate a random 'nice' colour
   * 
   * @returns The generated nice colour
   */
  function getColor() {
    function randomInt(min, max) {
      return Math.floor(Math.random() * (max - min + 1)) + min;
    }

    return () => {
      var h = randomInt(0, 360);
      var s = randomInt(42, 98);
      var l = randomInt(40, 90);
      return `hsl(${h},${s}%,${l}%)`;
    };
  };

/**
 * Format the text to be user friendly
 * - Capitalised
 * - All superflous references removed
 * 
 * @param {string} value The string to be formatted
 * @param {int} entry The index of the value in the chart
 * @returns A formatted string
 */
function formatChartText(value, _) {
  return capitalizeFirstLetter(String(value)
    .replace('_', ' ')
    .replace('.metrics.', ' - ')
    .replace('categories.', '')
    .replace('calculated - ', ''));
}

  // ----- Custom UI rendering
  /**
   * Generate the HTML to display a label on the pie chart
   * 
   * @param {object} labelObject The object used to render the secondary label
   * - cx: center x
   * - cy: center y
   * - midAngle: The angle in the middle of the sector
   * - innerRadius: The radius value of the inner part of the circle
   * - outerRadius: The radius value of the outer part of the circle
   * - percent: The percentage the value takes up of the whole circle
   * - Index: The index of the sector
   * @returns The HTML to render the Label for a sector
   */
  const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
    let labelModifier = 2;
    const labelRadius = outerRadius * labelModifier;
    const labelX = cx + labelRadius * Math.cos(-midAngle * RADIAN);
    const labelY = (cy + labelRadius * Math.sin(-midAngle * RADIAN));
    const valueRadius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const valueX = cx + valueRadius * Math.cos(-midAngle * RADIAN);
    const valueY = cy + valueRadius * Math.sin(-midAngle * RADIAN);
    if (percent !== 0) {
      let displayData = baseData;
      if (showBreakdown) {
        displayData = pieDataLvl3;
      }
      try {
        return (
          <>
            <text className="chart-pie-label-primary-title" x={labelX} y={labelY} fill="black" textAnchor={labelX > cx ? 'start' : 'end'} dominantBaseline="central">
              {`${formatChartText(displayData[index]['name'])}`}
            </text>
            <text x={valueX} y={valueY} fill="black" textAnchor={labelX > cx ? 'start' : 'end'} dominantBaseline="central">
              {! showBreakdown ? `$${displayData[index]['calculated']['metrics']['all']}` : `$${displayData[index]['value']}`}
            </text>
          </>
        );
      } catch (error) {
        return <></>;
      }
    }
  }

  /**
   * Generate the HTML to display a label on the pie chart
   * 
   * @param {object} labelObject The object used to render the secondary label
   * - cx: center x
   * - cy: center y
   * - midAngle: The angle in the middle of the sector
   * - innerRadius: The radius value of the inner part of the circle
   * - outerRadius: The radius value of the outer part of the circle
   * - percent: The percentage the value takes up of the whole circle
   * - Index: The index of the sector
   * @returns The HTML to render the Label for a sector
   */
  const renderSecondaryCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
    const labelRadius = outerRadius * 1.1;
    const labelX = cx + labelRadius * Math.cos(-midAngle * RADIAN);
    const labelY = cy + labelRadius * Math.sin(-midAngle * RADIAN);
    const valueRadius = innerRadius + (outerRadius - innerRadius) * 0.01;
    const valueX = cx + valueRadius * Math.cos(-midAngle * RADIAN);
    const valueY = cy + valueRadius * Math.sin(-midAngle * RADIAN);
    if (percent !== 0 && (pieDataLvl2[index] || pieDataLvl4[index])) {
      let displayData = pieDataLvl2;
      if (showBreakdown) {
        displayData = pieDataLvl4;
      }
      return (
        <>
          <text className="chart-pie-label-secondary-title" x={labelX} y={labelY} fill="black" textAnchor={labelX > cx ? 'start' : 'end'} dominantBaseline="central">
            {`${formatChartText(displayData[index]['name'])}`}
          </text>
          <text x={valueX} y={valueY} fill="black" textAnchor={labelX > cx ? 'start' : 'end'} dominantBaseline="central">
            {`$${displayData[index]['value']}`}
          </text>
        </>
      );
    }
  }

  /**
   * Generates custom HTML to dsplay information on the active graph point
   * 
   * @param {object} tooltipObject includes {active, payload, label} 
   *  - active: Whether the payload is active
   *  - payload: The details  of the graph for the active point
   *  - label: The base label of the active point
   * @returns HTML of the custom tool tip
   */
  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      let total = 0;
      return (
        <div className="tooltip chart-tooltip">
          <p className="label" key={label}>
            {`${capitalizeFirstLetter(label)}`}
          </p>
          {payload.map((labelPayload) => {
            if (showBreakdown && ! labelPayload.name.includes('calculated')) {
              total += labelPayload.value;
            }
            if (! showBreakdown && labelPayload.name.includes('calculated')) {
              total += labelPayload.value;
            }
            return (
              <p className="label" key={labelPayload.name} style={{ color: labelPayload.fill }}>
                {`${formatChartText(labelPayload.name)} : $${labelPayload.value.toLocaleString("en-US")}`}
              </p>
            );
          })}
          <Divider />
          <p className="label" key="total">
            {`Total: $${total.toLocaleString("en-US")}`}
          </p>
        </div>
      );
    }

    return null;
  }

  /**
   * From the properties of the graph (Data) generate a coloured legend with only 1 entry for each
   * metric
   * 
   * @param {object} props The props based through (by recharts)
   * @returns HTML output of the legend
   */
  function renderLegend(props) {
    const { payload } = props;
    let unique_metrics = [];
    return (
      <ul className="recharts-default-legend chart-legend">
        {
          payload.map((entry, index) => {
            // Since the keys follow x.y.z.q formart, split the string and get the last elements
            const metric = entry.dataKey.split('.').slice(-1)[0];
            // Check to see if the base metric name is unique
            if (unique_metrics.includes(metric)) {
              return null;
            }
            unique_metrics.push(metric);
            return (
              <li className="recharts-legend-item chart-legend-item" key={metric}>
                <svg className="recharts-surface chart-legend-icon" width="14" height="14" viewBox="0 0 32 32" version="1.1">
                  <path stroke="none" fill={entry.color} d="M0,4h32v24h-32z" className="recharts-legend-icon"></path>
                </svg>
                <span className="recharts-legend-item-text" style={{ color: entry.color }}>
                  <span style={{ color: entry.color }}> {capitalizeFirstLetter(metric)} </span>
                </span>
              </li>
            );
          })
        }
      </ul>
    );
  }

  /**
   * Generate a list of bars for the bar chart
   * 
   * Note:
   *    This is used to display the data
   * 
   * @returns Array[HTMLObject] an array of html objects
   */
  function generateLabelList() {
    if (! showBreakdown) {
      return parentDataMetrics.map((metric) => {
        return <Bar
          key={metric['name']}
          dataKey={'calculated.metrics.' + metric['name']}
          stackId="a"
          fill={metric['colour']}
        />;
      });
    } else {
      return [].concat(...baseData.map((sector) => {
        let categoriesBars = [];
        Object.keys(sector['categories']).forEach(function (categoryKey, index) {
          if (selectedCategories.includes(categoryKey) || selectedCategories.includes('All')) {
            const category = sector['categories'][categoryKey];
            Object.keys(category['metrics']).forEach(function (metricKey, index) {
              if (selectMetrics.includes(metricKey) || selectMetrics.includes('All')) {
                const metric = sector['categories'][categoryKey]['metrics'][metricKey];
                const metricColourData = parentDataMetrics.find(element => element.name === metricKey);
                if (metric > 0) {
                  categoriesBars.push(
                    <Bar
                      key={sector + '.categories.' + categoryKey + '.metrics.' + metricKey}
                      dataKey={'categories.' + categoryKey + '.metrics.' + metricKey}
                      stackId={categoryKey}
                      fill={metricColourData['colour']} >
                      {index === Object.keys(category['metrics']).length - 1 ? (
                        <LabelList
                          key={sector + '.categories.' + categoryKey + '.name'}
                          dataKey={'categories.' + categoryKey + '.name'}
                          position="insideTop"
                          angle="45"
                          formatter={formatChartText}
                        />
                      ) : (
                        null
                      )}
                    </Bar>
                  );
                }
              }
            });
          }
        });
        return categoriesBars;
      }));
    }   
  }

  return (
    <>
      <Box className="title-box">
        <Typography className="title text text-padding">Summary Controls</Typography>
        <Box className="header-btn">
          <Box>
            <FormControl className="data-switch right-align">
              <Stack direction="row" spacing={1}>
                <Typography>Pie</Typography>
                <Switch
                  checked={chartSwitch}
                  onChange={handleChartSwitch}
                />
                <Typography>Bar</Typography>
              </Stack>
            </FormControl>
            <FormControl className="select">
              <FormLabel id="sector-select" className="form-label">Sector</FormLabel>
              <Select
                labelId="sector-select"
                name="sector-select"
                multiple
                onChange={handleSectorChange}
                value={selectedSectors}
                renderValue={(selectedSectors) => {
                  return selectedSectors.map((val) => {
                    return formatChartText(val);
                  }).join(', ')
                }}
              >
                <MenuItem key="All" value="All">
                  <Checkbox checked={selectedSectors.indexOf('All') > -1} />
                  <ListItemText primary="All" />
                </MenuItem>
                {Array.from(parentDataSectors).map((key) => (
                  <MenuItem key={key} value={key}>
                    <Checkbox checked={selectedSectors.indexOf(key) > -1} />
                    <ListItemText primary={formatChartText(key)} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl className="select">
              <FormLabel id="category-select" className="form-label">Category</FormLabel>
              <Select
                labelId="category-select"
                name="category-select"
                multiple
                onChange={handleCategoryChange}
                value={selectedCategories}
                renderValue={(selectedCategories) => {
                  return selectedCategories.map((val) => {
                    return formatChartText(val);
                  }).join(', ')
                }}
              >
                <MenuItem key="All" value="All">
                  <Checkbox checked={selectedCategories.indexOf('All') > -1} />
                  <ListItemText primary="All" />
                </MenuItem>
                {Array.from(parentDataCategory).map((key) => {
                  return (
                    <MenuItem key={key} value={key}>
                      <Checkbox checked={selectedCategories.indexOf(key) > -1} />
                      <ListItemText primary={formatChartText(key)} />
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            <FormControl className="select">
              <FormLabel id="metric-select" className="form-label">Metric</FormLabel>
              <Select
                labelId="metric-select"
                name="metric-select"
                multiple
                onChange={handleMetricChange}
                value={selectMetrics}
                renderValue={(selectMetrics) => {
                  return selectMetrics.map((val) => {
                    return formatChartText(val);
                  }).join(', ')
                }}
              >
                <MenuItem key="All" value="All">
                  <Checkbox checked={selectMetrics.indexOf('All') > -1} />
                  <ListItemText primary="All" />
                </MenuItem>
                {Array.from(parentDataMetrics).map((metric) => {
                  return (
                    <MenuItem key={metric['name']} value={metric['name']}>
                      <Checkbox checked={selectMetrics.indexOf(metric['name']) > -1} />
                      <ListItemText primary={capitalizeFirstLetter(metric['name'].replace('_', ''))} />
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            <FormControl className="date-form">
              <Stack direction="row" spacing={1}>
                <FormControl className="select">
                  <FormLabel id="date-select">
                    Date Type
                    <MuiToolTip title={dateToolTip} enterDelay={10} className="date-tooltip">
                      <IconButton>
                        <HelpOutlineIcon />
                      </IconButton>
                    </MuiToolTip>
                  </FormLabel>
                  <Select
                    labelId="date-select"
                    name="date-select"
                    onChange={handleDateTypeChange}
                    value={dateType}
                    className="date-type-selector"
                  >
                    <MenuItem key="Due" value="Due">
                      <ListItemText primary="Due" />
                    </MenuItem>
                    <MenuItem key="Start" value="Start">
                      <ListItemText primary="Start" />
                    </MenuItem>
                  </Select>
                </FormControl>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <FormControl>
                    <FormLabel id="start_date" className="form-label">Start Date</FormLabel>
                    <DatePicker
                      value={dates['start_date']}
                      onChange={(newValue) => {
                        setDates({ ...dates, start_date: newValue.toJSON() })
                      }}
                      renderInput={(props) => {
                        let inputProps = props.inputProps;
                        inputProps = { ...inputProps, className: "date-input" };
                        props.inputProps = inputProps;
                        return <TextField labelId={'start_date'}
                          name={'start_date'} {...props} size="small" helperText={null} />
                      }}
                    />
                  </FormControl>
                </LocalizationProvider>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <FormControl>
                    <FormLabel id="end_date" className="form-label">End Date</FormLabel>
                    <DatePicker
                      value={dates['end_date']}
                      onChange={(newValue) => {
                        setDates({ ...dates, end_date: newValue.toJSON() })
                      }}
                      renderInput={(props) => {
                        let inputProps = props.inputProps;
                        inputProps = { ...inputProps, className: "date-input" };
                        props.inputProps = inputProps;
                        return <TextField labelId={'end_date'}
                          name={'end_date'} {...props} size="small" helperText={null} />
                      }}
                    />
                  </FormControl>
                </LocalizationProvider>
              </Stack>
              <Button 
                className="clear-dates"
                variant="outlined"
                color="error"
                onClick={clearDates}
              >
                Clear Dates
              </Button>
            </FormControl>
            <FormControl className="data-switch">
              <FormLabel className="form-label">Show Category Breakdown</FormLabel>
              <Stack direction="row" spacing={1} alignItems="center">
                <Switch
                  checked={showBreakdown}
                  onChange={handleBreakdownSwitch}
                />
              </Stack>
            </FormControl>
          </Box>
        </Box>
      </Box>
      {/* Chart */}
      <Box className="content">
        {chartType === 'bar' && (
          <BarChart
            className="bar-chart"
            width={1550}
            height={900}
            data={baseData}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" tickFormatter={formatChartText} />
            <YAxis />
            <Tooltip content={<CustomTooltip />} />
            <Legend content={renderLegend} />
            { generateLabelList() }
          </BarChart>
        )}
        {chartType === 'pie' && (
          <PieChart
            className="pie-chart"
            width={1550}
            height={900}
          >
            {! showBreakdown ? (
              <>
                {/* Sector Pie chart */}
                <Pie
                  data={baseData}
                  dataKey={'calculated.metrics.all'}
                  cx="50%"
                  cy="50%"
                  innerRadius={0}
                  outerRadius={220}
                  fill="#82ca9d"
                  label={renderCustomizedLabel}
                />
                <Pie
                  data={pieDataLvl2}
                  nameKey="name"
                  dataKey="value"
                  cx="50%"
                  cy="50%"
                  innerRadius={220}
                  outerRadius={280}                  
                  fill="#82ca9d"
                  label={renderSecondaryCustomizedLabel}
                >
                  <Tooltip content={<CustomTooltip />} />
                </Pie>
              </>
            ) : (
              <>
                {/* Category Pie chart */}
                <Pie
                  data={pieDataLvl3}
                  dataKey="value"
                  nameKey="name"
                  cx="50%"
                  cy="50%"
                  innerRadius={0}
                  outerRadius={220}
                  fill="#82ca9d"
                  label={renderCustomizedLabel}
                />
                <Pie
                  data={pieDataLvl4}
                  dataKey="value"
                  nameKey="name"
                  cx="50%"
                  cy="50%"
                  innerRadius={220}
                  outerRadius={280}
                  fill="#82ca9d"
                  label={renderSecondaryCustomizedLabel}
                />
              </>
            )}
          </PieChart>
        )}
      </Box>
    </>
  )
}
