import { useEffect, useState, useCallback } from 'react';
import { Link } from 'react-router-dom';
import {
  Box, Button, Typography, FormControl,
  Chip, Stack, Switch, IconButton,
  CircularProgress, Tooltip, Dialog,
  DialogActions, DialogContent, DialogTitle,
  FormLabel, Select, MenuItem, TextField,
  Snackbar, Alert
} from '@mui/material';

// DatePicker components
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'

import { useDropzone } from 'react-dropzone';

import { DataGrid, GridFilterModel } from '@mui/x-data-grid';

import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

import { getTenders, getTenderApprovals,
  handleFileUpload, deleteTender, handleTenderReportDownload } from '../../api/tender/index.js';
import { createDataEntry } from '../../api/tender/index.js';
import TenderChart from './TenderChart.js';

export default function TenderSummary() {

  const [loaded, setLoaded] = useState(false);
  const [tenderList, setTenderList] = useState([]);
  const [chartSwitch, setChartSwitch] = useState(true);

  const [open, setOpen] = useState(false);
  const [openNoteEditor, setNoteEditorOpen] = useState(false);
  const [tenderNotes, setTenderNotes] = useState('');
  const [editingTender, setEditingTender] = useState({});
  const [openDownloadTenderReport, setOpenDownloadTenderReport] = useState(false);
  const [downloadDates, setDownloadDates] = useState({});


  const [files, setFiles] = useState([]);
  const [tenderUploadType, setTenderUploadType] = useState('');

  const [flashOpen, setFlashOpen] = useState(false);
  const [flashType, setFlashType] = useState('success');
  const [flashMessage, setFlashMessage] = useState('');

  const [statusOptions, setStatusOptions] = useState([]);

  const [filterModel, setFilterModel] = useState({
    items: [ ],
  });

  // The Columns and how each should be display
  const columns = [
    {
      field: 'client_code',
      headerName: 'Tender Code',
      width: 130,
      flex: 1,
      renderCell: (params) =>
        <a href={`tenders/${params.row.id}`}>{params.row.client_code}</a>
    },
    {
      field: 'project_name',
      headerName: 'Job Name',
      width: 130,
      flex: 1,
      renderCell: (params) =>
        <a href={`tenders/${params.row.id}`}>{params.row.project_name}</a>
    },
    {
      field: 'status',
      headerName: 'Status',
      width: 130,
      flex: 0.8,
      type: 'singleSelect',
      valueOptions: statusOptions,
      valueFormatter: (params) => {
        if (params.value) {
          return capitalizeFirstLetter(params.value);
        } else {
          return '';
        }
      },
      renderCell: (params) => {
        return (
          <Stack direction="row" spacing={1}>
            {params.row.status &&
              <Chip
                label={capitalizeFirstLetter(params.row.status)}
                color={getChipProgress(params.row.status)}
                variant="outlined"
              />
            }
            {params.row?.progress?.current_approval &&
              <Chip
                label={params.row?.progress?.current_approval}
                color={getChipProgress(params.row.status)}
                variant="outlined"
              />
            }
          </Stack>
        );
      }
    },
    {
      field: 'current_value', headerName: 'Current value', width: 130, flex: 0.5
    },
    {
      field: 'allocated', headerName: 'Current Assignee', width: 130, flex: 1
    },
    {
      field: 'start_date',
      headerName: 'Start Date',
      width: 60,
      flex: 0.5,
      renderCell: (params) =>
        <p>{formatDate(params.row.start_date)}</p>
    },
    {
      field: 'due_date',
      headerName: 'Due Date',
      width: 60,
      flex: 0.5,
      renderCell: (params) =>
        <p>{formatDate(params.row.due_date)}</p>
    },
    {
      field: 'progress',
      headerName: 'Progress',
      description: 'This column has a value getter and is not sortable.',
      sortable: false,
      width: 220,
      renderCell: (params) => {
        return (
          <Stack direction="row" spacing={1}>
            <Tooltip title={`Go No Go: ${params.row?.progress?.gng || 'na'}`}>
              <Chip
                label="GNG"
                color={getChipProgress(params.row?.progress?.gng)}
                variant="outlined"
              />
            </Tooltip>
            <Tooltip title={`Start Up: ${params.row?.progress?.startup || 'na'}`}>
              <Chip
                label="SU"
                color={getChipProgress(params.row?.progress?.startup)}
                variant="outlined"
              />
            </Tooltip>
            <Tooltip title={`Data Entry: ${params.row?.progress?.data_entry || 'na'}`}>
              <Chip
                label="DE"
                color={getChipProgress(params.row?.progress?.data_entry)}
                variant="outlined"
              />
            </Tooltip>
          </Stack>
        )
      }
    },
    {
      field: 'notes',
      headerName: 'Notes',
      sortable: false,
      width: 130,
      renderCell: (params) => {
        return (<Button
          className="btn-primary btn-right"
          onClick={() => {
            handleEditTenderNotes(params.row);
          }}>Notes</Button>)
      }
    },
    {
      field: 'actions',
      headerName: 'Actions',
      sortable: false,
      width: 150,
      renderCell: (params) => {
        return <Stack direction="row" spacing={1}>
          <Link to={`/home/tenders/${params.row.id}`}>
            <IconButton aria-label="delete" size="large">
              <EditIcon fontSize="inherit" />
            </IconButton>
          </Link>

          <IconButton 
            aria-label="delete"
            size="large"
            onClick={() => {
              handelDeleteTender(params.row)
            }}
          >
            <DeleteIcon fontSize="inherit" />
          </IconButton>
        </Stack>
      }
    }
  ];

  /**
   * Get all the tenders from the backend
   */
  useEffect(() => {
    syncTenders();

    const tenderFilter = localStorage.getItem('tender_filter');
    if (tenderFilter) {
      setFilterModel(JSON.parse(tenderFilter));
    }
  }, [])

  /**
   * Handle the switching between datatable and chart
   * 
   * @param {DOMEvent} event The OnChange event
   */
  function handleChartSwitch(event) {
    let value = event.target.checked;
    setChartSwitch(value);
  }

  /**
   * Handle the closing of the review modal
   */
  function handleClose() {
    setOpen(false);
  }

  /**
   * Handle changing the onchange event for the tender upload selector
   * 
   * @param {DOMEvent} event The OnChange event
   */
  function handleTenderUploadType(event) {
    const key = event.target.value;
    setTenderUploadType(key);
  }

  /**
   * Open the note editor by loading in the selected data entry notes
   * 
   * @param {object} row The tender object containing the notes for the DE object
   * @returns null return early condition
   */
  function handleEditTenderNotes(row) {

    if (row['status'] === 'draft') {
      setFlashType('error');
      setFlashMessage('Tender is a draft and cannot have notes');
      setFlashOpen(true);
      return;
    } else if (! row['DataEntry.id']) {
      row['DataEntry.fields'] = JSON.stringify({
        'notes': {
          'key': '',
          'value': '',
        }
      });
      row['DataEntry.status'] = 'na';
      row['DataEntry.tender_id'] = row['id'];
    }

    let deFields = row['DataEntry.fields'];
    if (typeof(deFields) !== 'object') {
      deFields = JSON.parse(deFields);
    }

    if (! deFields['notes']) {
      setTenderNotes('');
    } else {
      setTenderNotes(deFields['notes']['key']);
    }
    
    setEditingTender(row);
    setNoteEditorOpen(true);
  }

  /**
   * Match the tender submission status with the Chip progress
   * 
   * @param {string} progress The tender status
   * @returns string: The Corresponding chip progression
   */
  function getChipProgress(progress) {
    switch (progress) {
      case 'na':
      case 'lost':
      case 'other':
        return 'error';
      case 'wip':
        return 'warning';
      case 'withdrawn':
        return 'secondary';
      case 'approval':
      case 'submitted':
        return 'primary';
      case 'done':
      case 'completed':
      case 'won':
        return 'success';
      default:
        return 'error';
    }
  }

  /**
   * Format the data string by removing everything past the T
   * This if for date strings that have format <Date>T<Time>
   * @param {string} date 
   * @returns string: the displayable date
   */
  function formatDate(date) {
    if (date) {
      const yearDate = String(date).split('T')[0];
      // Reverst the year focused date to be day focused
      const dayDate = yearDate.split('-').reverse().join('-');
      return dayDate;
    } else {
      return '-';
    }
  }

  /**
   * Capatalise the first letter of the string for
   * display purposes
   * 
   * @param {string} string 
   * @returns string: the string with the first letter capatilsed
   */
  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  /**
   * Handle deleting an Tender object and modifying the list
   * 
   * @param {object} row 
   */
  async function handelDeleteTender(row) {
    const TenderId = row.id;
    await deleteTender(TenderId);

    // remove the tender from the list
    const deletedIndex = tenderList.findIndex((element) => element == row);
    let workingTenders = JSON.parse(JSON.stringify(tenderList));
    workingTenders.splice(deletedIndex, 1);
    setTenderList(workingTenders);
  }

  /**
   * Get the tenders from the backend and parse them
   * to be displayable in either the table or the charts
   */
  const syncTenders = async () => {
    setLoaded(false);
    await getTenders().then(async (res) => {
      let tenders = res['data'];
      if (! Array.isArray(tenders)) {
        tenders = [tenders];
      }
      for (const tender of tenders) { 
        const gngStatus = tender['GoNoGo.status'];
        const startUpStatus = tender['StartUp.status'];
        const dataEntryStatus = tender['DataEntry.status'];

        const steps = ['GoNoGo', 'StartUp', 'DataEntry'];
        for (let step in steps) {
          step = steps[step];
          if (tender[step + '.status'] === 'approval') {
            // Get approvals
            const objectId = tender[step + '.id'];
            let approved = 0;
            const tenderApprovalRes = await getTenderApprovals(objectId);
            tenderApprovalRes.data.forEach(approval => {
              if (approval.status === 'approved') {
                approved++;
              }
            })
            tender['progress'] = {
              ...tender['progress'],
              'current_approval': approved + '/' + tenderApprovalRes.data.length
            };
            break;
          }
        }

        // Track the statuses available to filter from
        setStatusOptions(prevStatuses => {
          if (prevStatuses.includes(tender['status'])) {
            return prevStatuses;
          } else {
            return [...prevStatuses, tender['status']];
          }
        });

        // The overall progress of the tender and its steps
        tender['progress'] = {
          ...tender['progress'],
          'gng': gngStatus,
          'startup': startUpStatus,
          'data_entry': dataEntryStatus
        };

        // Get the value of the tender
        const gngFields = JSON.parse(tender['GoNoGo.fields']);
        const dataEntryFields = JSON.parse(tender['DataEntry.fields']);
        let tenderValue = '-';

        // Create our number formatter.
        const formatter = new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'USD',
          minimumFractionDigits: 1,
          maximumFractionDigits: 2
        });

        if (dataEntryFields && dataEntryFields['resubmitted-value'] && dataEntryFields['resubmitted-value']['key'] !== '') {
          tenderValue = formatter.format(dataEntryFields['resubmitted-value']['key'].replace('$', '').replace(/,/g, ''));
        } else if (dataEntryFields && dataEntryFields['submitted-value']) {
          tenderValue =  formatter.format(dataEntryFields['submitted-value']['key'].replace('$', '').replace(/,/g, ''));
        } else if (gngFields && gngFields['sandbox-val']) {
          tenderValue = gngFields['sandbox-val']['key'];
        }
        tender['current_value'] = tenderValue;
      }

      if (localStorage.getItem('has_draft')) {
        const draftTender = {
          'id': 'draft',
          'client_code': 'draft',
          'status': 'draft',
          'current_value': '-',
          'allocated': '-',
          'start_date': '-',
          'due_date': '-',
        };
        tenders.unshift(draftTender);
      }
      if (tenderList !== tenders) {
        setTenderList(tenders);
      }
    });
    setLoaded(true);
  }

  /**
   * Close the notes modal
   */
  function handleNotesClose() {
    setNoteEditorOpen(false);
  }

  /**
   * Handle the note field changing
   * 
   * @param {DOMevent} event 
   */
  function handleNotes(event) {
    const key = event.target.value;
    setTenderNotes(key);
  }

  /**
   * Save the notes to the data entry, either creating one or updating it
   */
  function saveNotes() {

    const deFields = JSON.parse(editingTender['DataEntry.fields']);
  
    if (! deFields['notes']) {
      deFields['notes'] = {
        'key': '',
        'value': ''
      };
    }

    deFields['notes']['key'] = tenderNotes;

    // Stringify the fields and put them back into the tender object
    editingTender['DataEntry.fields'] = JSON.stringify(deFields);
    
    const dataEntryData = {
      'dataEntryFormInput': {
        'tender_id': editingTender.id,
        'fields': deFields,
        'status': editingTender['DataEntry.status']
      }
    };
    createDataEntry(dataEntryData);
    setNoteEditorOpen(false);
  }

  /**
   * Show the upload data model
   */
  function showUpload() {
    setOpen(true);
  }

  /**
   * Creare a form data object and send it to the backend
   * to be handled
   */
  function uploadData() {
    if (files.length !== 0) {
      const formDataObj = new FormData()
      formDataObj.append('tender_type', tenderUploadType)
      for (let i = 0; i < files.length; i++) {
        formDataObj.append('files', files[i]);
          if (i === files.length - 1) {
              handleFileUpload(formDataObj);
          }
      }
    }
    setOpen(false);
  }

  const onDrop = useCallback(newFiles => {
    setFiles(prevFiles => [...prevFiles, ...newFiles]);
  }, [])

  const { getRootProps, getInputProps} = useDropzone({onDrop});

  const acceptedFileItems = files.map((file, i) => (
      <li key={file.path}>
          {file.name} : {file.size} bytes.
      </li>
  ));

  /**
   * Open the Download tender report modal
   */
  function showDownload() {
    setOpenDownloadTenderReport(true);
  }

  /**
   * Close the Download tender report modal
   */
  function handleDownloadClose() {
    setOpenDownloadTenderReport(false);
  }

  /**
   * Formats the Json files for form fields into ordered column names
   * 
   * @param {Array} fieldsBase The data of one of the form field JSON files
   * @returns An array of column names
   */
  function getColumnNames(fieldsBase) {
    let newColumns = [];
    fieldsBase.forEach(element => {
      if (element.type === 'group') {
        element.values.forEach(element => {
          newColumns.push(element.id);
        });
      } else {
        newColumns.push(element.id);
      }
    });
    return newColumns;
  }

  /**
   * Iterate through the fields and assign the values to a dictionary
   * 
   * @param {str} formFields The raw JSON from the fields value of the Step forms (GNG etc)
   * @param {Array} fieldsBaseData The Base representation of the step form fields
   * @returns 
   */
  function parseFormFields(formFields, fieldsBaseData) {
    const fieldsValues = {};
    if (formFields) {
      formFields = JSON.parse(formFields);
      fieldsBaseData.forEach(element => {
        if (element.type === 'group') {
          element.values.forEach(element => {
            fieldsValues[element.id] = parseFormValue(formFields, element.id);
          });
        } else {
          fieldsValues[element.id] = parseFormValue(formFields, element.id);
        }
      });
    } else {
      fieldsBaseData.forEach(element => {
        if (element.type === 'group') {
          element.values.forEach(element => {
            fieldsValues[element.id] = '';
          });
        } else {
          fieldsValues[element.id] = '';
        }
      });
    }
    return fieldsValues;
  }

  /**
   * Format a field to be CSV value
   * 
   * @param {str} fieldValue The field value to be formatted
   * @returns The CSV friendly field
   */
  function formatField(fieldValue) {
    if (! fieldValue) {
      return fieldValue;
    }
    if (/\r|\n/.exec(fieldValue)) {
      // Surround fields with newlines with quotes to make it CSV friendly
      fieldValue = `"${fieldValue}"`;
    }
    return fieldValue.replaceAll(',', '');
  }

  /**
   * Get a value from the form field
   * 
   * @param {Object} formFields The form field to have the value extracted from
   * @param {str} formFieldId The id to select which field to extract
   * @returns the form field value or an empty string
   */
  function parseFormValue(formFields, formFieldId) {
    if (formFields[formFieldId]) {
      if (formFields[formFieldId]['key'] || formFields[formFieldId]['key'] === '') {
        return formatField(formFields[formFieldId]['key']);
      } else {
        return formatField(formFields[formFieldId]);
      }
    } else {
      return '';
    }
  }

  /**
   * Create and download a CSV by pulling all of the tender data from the backend
   */
  function downloadCSV() {

    const dataEntryFieldsBase = require('../../resources/data/dataentry.json');
    const gngFieldsBase = require('../../resources/data/gonogo.json');
    const startUpFieldsBase = require('../../resources/data/startup.json');

    // Get columns
    let columns = [
      'client_code',
      'client_name',
      'project_name',
      'allocated', 
      'allocated_id',
      'status',
      'due_date',
      'start_date',
      'gng_id',
    ];

    const gngFieldNames = getColumnNames(gngFieldsBase['data']);
    columns.push(...gngFieldNames);
    columns.push(...['gng_status', 'startup_id']);

    const startupFieldNames = getColumnNames(startUpFieldsBase['data']);
    columns.push(...startupFieldNames);
    columns.push(...['startup_status', 'dataentry_id']);

    const dataEntrypFieldNames = getColumnNames(dataEntryFieldsBase['data']);
    columns.push(...dataEntrypFieldNames);
    columns.push('dataentry_status');

    const data = {
      'date_range': downloadDates
    };

    handleTenderReportDownload(data).then((response) => {
      const rawTenders = response.data;

      let tenders = [];
      rawTenders.forEach(element => {
        let formattedTender = {
          'client_code': formatField(element.client_code),
          'client_name': formatField(element.client_name),
          'project_name': formatField(element.project_name),
          'status': element.status,
          'due_date': element.due_date,
          'start_date': element.start_date,
          'allocated': formatField(element['User.name']),
          'allocated_id': element['User.id']
        };

        let gngFields = element['GoNoGo.fields'];
        let startUpFields = element['StartUp.fields'];
        let dataEntryFields = element['DataEntry.fields'];

        formattedTender['gng_id'] = element['GoNoGo.id'];
        formattedTender['gng_status'] = element['GoNoGo.status'];
        const gngFormattedFields = parseFormFields(gngFields, gngFieldsBase['data']);
        formattedTender = {...formattedTender, ...gngFormattedFields};

        formattedTender['startup_id'] = element['StartUp.id']
        formattedTender['startup_status'] = element['StartUp.status']
        const startupFormattedFields = parseFormFields(startUpFields, startUpFieldsBase['data']);
        formattedTender = {...formattedTender, ...startupFormattedFields};

        formattedTender['dataentry_id'] = element['DataEntry.id']
        formattedTender['dataentry_status'] = element['DataEntry.status']
        const dateEntryFormattedFields = parseFormFields(dataEntryFields, dataEntryFieldsBase['data']);
        formattedTender = {...formattedTender, ...dateEntryFormattedFields};

        tenders.push(formattedTender);
      });

      let csvContent = 'data:text/csv;charset=utf-8,';
      let headerContent = columns.join(',') + "\n";
      let rowContent = tenders.map(e => columns.map(col => e[col]).join(',')).join("\n");
      var encodedUri = encodeURI(csvContent + headerContent + rowContent);
      var link = document.createElement('a');
      link.setAttribute('href', encodedUri);
      link.setAttribute('download', 'tender_export.csv');
      document.body.appendChild(link); // Required for FF
      link.click();
    });
  }

  return (
    <>
      {/* Tender Summary */}
      <Box className="title-box">
        <Typography className="title text user-title text-padding">Tender Summary</Typography>
        <Stack className="header-btn">
            <Box>
              <Button
                className="btn-primary"
                variant="contained"
                onClick={() => window.location.href = 'tenders/create'}
              >
                Create Tender
              </Button>
            </Box>
            <Box>
              <FormControl className="data-switch">
                <Stack direction="row" spacing={1} alignItems="center">
                  <Typography>Summary</Typography>
                  <Switch
                    checked={chartSwitch}
                    onChange={handleChartSwitch}
                  />
                  <Typography>Table</Typography>
                </Stack>
              </FormControl>
            </Box>
            <Box className="stack-divider"/>
            <Box>
              <Button
                className="btn-primary"
                variant="contained"
                onClick={() => showUpload()}
              >
                Upload
              </Button>
            </Box>
            <Box>
              <Button
                className="btn-primary"
                variant="contained"
                onClick={() => showDownload()}
              >
                Download Tenders
              </Button>
            </Box>
        </Stack>
      </Box>
      {/* Data table */}
      {chartSwitch && (
        <>
        {! loaded ?
          (
            <Box className="tender-loader">
            <CircularProgress />
          </Box>
          ) : (
        <Box className="content table-data">
           {chartSwitch  &&
              <Box className="filter-clear">
                <Button 
                  variant="outlined"
                  color="error"
                  disabled={filterModel.items.length === 0}
                  onClick={() => {
                    setFilterModel({'items': []});
                    localStorage.removeItem('tender_filter');
                  }}
                >
                  Clear Table Filters
                </Button>
              </Box>
            }
          <DataGrid
            rows={tenderList}
            columns={columns}
            pageSize={15}
            rowsPerPageOptions={[15]}
            filterModel={filterModel}
            onFilterModelChange={(newFilterModel) => {
              localStorage.setItem('tender_filter', JSON.stringify(newFilterModel));
              setFilterModel(newFilterModel);
            }}
          />
        </Box>
          )}
          </>
      )}
      {/* Chart */}
      {! chartSwitch && (
        <Box className="content table-data">
          <TenderChart
            key={tenderList}
            tenderList={tenderList}
          />
        </Box>
      )}
      {/* Upload existing tenders Dialog */}
      <Dialog open={open} onClose={handleClose}>
          <DialogTitle className="feedback-dialog">Upload existing data</DialogTitle>
          <DialogContent>
            <Box>
              <FormControl className="dialog-label">
                <FormLabel id="tender-type">Tender File Type</FormLabel>
                <Select
                  labelId="tender-type"
                  name="tender-type"
                  onChange={handleTenderUploadType}
                  value={tenderUploadType}
                  className="tender-select"
                >
                  <MenuItem value="register">Register</MenuItem>
                  <MenuItem value="tracking">Tracking</MenuItem>
                </Select>
              </FormControl>
            </Box>
            
            <Box className="fileupload">
              <div {...getRootProps({className: "dropzone"})}>
                  <input {...getInputProps()} />
                      <p>Drag & Drop files here, or click to select.</p>
              </div>
            </Box>
            <Box className="file-list">
                <h4>Files Attached:</h4>
                {acceptedFileItems.length ?
                <ul>{acceptedFileItems}</ul>
                : <p>There are currently no files attached.</p>
                }
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button onClick={(event) => uploadData()}>Upload Data</Button>
          </DialogActions>
        </Dialog>
        {/* Tender notes Dialog */}
        <Dialog open={openNoteEditor} onClose={handleNotesClose}>
          <DialogTitle className="feedback-dialog">Edit Tender notes</DialogTitle>
          <DialogContent>
            <Box>
              <FormControl className="dialog-label">
                <FormLabel id="tender-type">Tender notes</FormLabel>
                <TextField
                  value={tenderNotes}
                  onChange={handleNotes}
                  multiline={true}
                  minRows={5}
                  maxRows={20}
                />
              </FormControl>
            </Box>
          </DialogContent>
          <DialogActions>
          <Button onClick={handleNotesClose}>Cancel</Button>
            <Button onClick={(event) => saveNotes()}>Save</Button>
          </DialogActions>
        </Dialog>
        {/* Download tender report Dialog */}
        <Dialog open={openDownloadTenderReport} onClose={handleDownloadClose}>
          <DialogTitle className="feedback-dialog">Download Tender CSV</DialogTitle>
          <DialogContent>
            <Stack direction="row" className="date-rows">
              <Box>
                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-au">
                  <FormLabel id="start_date" className="form-label">Start Date</FormLabel>
                  <DatePicker
                    value={downloadDates['start_date']}
                    onChange={(newValue) => {
                      setDownloadDates({ ...downloadDates, ['start_date']: newValue })
                    }}
                    renderInput={(props) => {
                      let inputProps = props.inputProps;
                      inputProps = { ...inputProps, className: "date-input" };
                      props.inputProps = inputProps;
                      return <TextField labelid="start_date" className="date-box"
                        name="start_date"  {...props} size="small" helperText={null} />
                    }}
                  />
                </LocalizationProvider>
              </Box>
              <Box>
                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-au">
                  <FormLabel id="end_date" className="form-label">End Date</FormLabel>
                  <DatePicker
                    value={downloadDates['end_date']}
                    onChange={(newValue) => setDownloadDates({ ...downloadDates, ['end_date']: newValue })}
                    renderInput={(props) => {
                      let inputProps = props.inputProps;
                      inputProps = { ...inputProps, className: "date-input" };
                      props.inputProps = inputProps;
                      return <TextField labelid="end_date" className="date-box"
                        name="end_date"  {...props} size="small" helperText={null} />
                    }}
                  />
                </LocalizationProvider>
              </Box>
            </Stack> 
          </DialogContent>
          <DialogActions>
          <Button onClick={handleDownloadClose}>Cancel</Button>
          <Button onClick={(event) => downloadCSV()}>Download</Button>
          </DialogActions>
        </Dialog>
        <Snackbar open={flashOpen} autoHideDuration={4000} onClose={() => setFlashOpen(false)}>
          <Alert className="flash" onClose={() => setFlashOpen(false)} severity={flashType}>
            {flashMessage}
          </Alert>
        </Snackbar>
    </>
  )
}
