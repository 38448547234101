import React from 'react';
import { FormControl, FormLabel, Select, MenuItem, FormHelperText } from '@mui/material';

/**
 * The common Select component
 */
class FormSelect extends React.Component {

  /**
   * The On Change function to execute when the select field changes
   * 
   * @param {string} event The event of select field on change
   */
  onChange = (event) => {
    this.props.parentCallback(event);
    event.preventDefault();
  }

  render() {
    return (
      <FormControl className="form-label">
        <FormLabel id={this.props.field.id}>{this.props.field.label}</FormLabel>
        <Select
          labelId={this.props.field.id}
          name={this.props.field.id}
          onChange={this.onChange}
          defaultValue={this.props.selectedValue}
          style={{ textAlign: "left" }}
          error={this.props.field.isError}
        >
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          {this.props.field.values.map(select =>
            <MenuItem key={select.name} value={select.name}>{select.name}</MenuItem>
          )}
        </Select>
        {this.props.field.isError &&
          <FormHelperText className="error">{this.props.field.errorText}</FormHelperText>
        }
      </FormControl>
    );
  }
}
export default FormSelect;
