import { AppBar, Box, Toolbar } from '@mui/material';

export default function Footer() {
  return (
    <>
      <Box className="footer">
        <a className="logout" href="/logout">Logout</a>
      </Box>
    </>
  );
}
