import axios from 'axios';

/**
 * This file contains all of the API calls related specifcally to the Users
 * 
 * Get User
 * Get Users
 * Update User
 * Create User (signup)
 * Get Alloctable User
 * 
 */

/**
 * Get the current signed in user
 * 
 * @returns User object
 */
export const getUser = async () => {
    return await axios.get('/api/user/state')
    .then((res) => {
        return res.data;
    })
    .catch((err) => {
        console.log(err);
    });
}

/**
 * Get all the users
 * @returns Array[Object] An array of users
 */
export const getUsers = async () => {
    return await axios.get('/api/users')
    .then((res) => {
        return res.data;
    })
    .catch((err) => {
        console.log(err);
    });
}

/**
 * Get all the users that are allocatable
 * 
 * @returns Array[Object] An array of users that can
 * be allocated to 
 */
export const getAllocatableUsers = async () => {
    return await axios.get('/api/users/allocatable')
    .then((res) => {
        return res.data;
    })
    .catch((err) => {
        console.log(err);
    });
}

/**
 * Signup a user using a username (email) and password
 * 
 * @param {object} user The username and pwd of a user
 * @returns the user object
 */
export const signUp = async (user) => {
    return await axios.post('/api/users/create', user)
    .then((res) => {
        return res.data;
    })
    .catch((err) => {
        console.log(err);
    });
}

/**
 * Signup a user using a username (email) and password
 * 
 * @param {object} user The username and pwd of a user
 * @returns the user object
 */
export const logout = async () => {
    return await axios.post('/auth/logout')
    .then((res) => {
        return res.data;
    })
    .catch((err) => {
        console.log(err);
    });
}

/**
 * Update a specifc user
 * 
 * NOTE:
 *      Required in the user object is the user email,
 *      this also can't be different from the intended
 *      user to update
 * 
 * @param {object} user The user obkect to update
 * @returns The updated user
 */
export const updateUsers = async (user) => {
    const paramData = { user }
    return await axios.post('/api/users', paramData)
    .then((res) => {
        return res.data;
    })
    .catch((err) => {
        console.log(err);
    });
}

/**
 * Delete a specifc user
 * 
 * NOTE:
 *      Required in the user object is the user email,
 *      this also can't be different from the intended
 *      user to update
 * 
 * @param {object} user The user obkect to update
 * @returns The updated user
 */
export const deleteUsers = async (user) => {
    return await axios.delete('/api/users/' + user)
    .then((res) => {
        return res.data;
    })
    .catch((err) => {
        console.log(err);
    });
}
