
import React from 'react';
import { FormControl, Radio, RadioGroup, FormControlLabel, FormLabel } from '@mui/material';

/**
 * The common radio button component
 */
class FormRadioButton extends React.Component {

  /**
   * The On Change function to execute when the radio button changes
   * 
   * @param {string} event The event of radio button on change
   */
  onChange = (event) => {
    this.props.parentCallback(event);
    event.preventDefault();
  }

  render() {
    return (
      <FormControl className="form-label">
        <FormLabel id={this.props.field.id}>{this.props.field.label}</FormLabel>
        <RadioGroup
          id={this.props.field.id}
          aria-labelledby={"radio-buttons-group-" + this.props.field.id}
          name={this.props.field.id}
          defaultValue={this.props.selectedValue}
          onChange={this.onChange}
        >
          {this.props.field.values.map(radio =>
            <FormControlLabel key={radio.name} value={radio.name} control={<Radio />} label={radio.name} />
          )}
        </RadioGroup>
      </FormControl>
    );
  }
}
export default FormRadioButton;
