
import {
  Box, FormControl, FormLabel,
  Grid, Paper, IconButton
} from '@mui/material';
import { styled } from '@mui/material/styles';
import ControlPointIcon from '@mui/icons-material/ControlPoint';

import FormTextField from '../HelperComponents/FormTextfield'
import FormRadioButton from '../HelperComponents/FormRadioButton';
import FormSelect from '../HelperComponents/FormSelect';
import FormDatePicker from '../HelperComponents/FormDatePicker';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

/**
 * Generate the HTML needed for the fields in each of the three tender phases
 * 
 * @param {object} field The fields to render
 * @param {Function} onChangeHandler  The function to change the field value
 * @param {Function} handleDateChange The function to change the date field value
 * @param {Object} dates The date object hold specifically all the dates 
 * @param {Boolean} reviewable If the field should be in review mode
 * @param {Function} reviewHandler The function when the fields needs to be reviewed
 * @returns HTML: Returns a HTML to be rendered
 */
export default function getFieldHTML(field, onChangeHandler,
    handleDateChange, dates,
    reviewable = false, reviewHandler = null) {

  let selectedValue = '';
  if (field.selectedValue) {
    selectedValue = field.selectedValue;
    if (selectedValue.hasOwnProperty('key')) {
      selectedValue = selectedValue.key;
    }
  }
  const width = field.type === 'group' ? 10 : 5;
  return (<Grid item key={field.id + '_g'} xs={width} className={(field.type === 'group' ? 'group-field' : '') + (field.style ? ' ' + field.style : '')}>
    <Item key={field.id + '_i'} elevation={0} className="form-item">
      {reviewable &&
        <Box className="review-button">
          <IconButton color="primary" aria-label="feedback" onClick={() => reviewHandler(field)}>
            <ControlPointIcon />
          </IconButton>
        </Box>
      }
      {field.type === 'text' &&
        <FormTextField
          field={field}
          selectedValue={selectedValue}
          parentCallback={onChangeHandler}
        />
      }
      {field.type === 'radio' &&
        <FormRadioButton
          field={field}
          selectedValue={selectedValue}
          parentCallback={onChangeHandler}
        />
      }
      {field.type === 'select' &&
        <FormSelect
          field={field}
          selectedValue={selectedValue}
          parentCallback={onChangeHandler}
        />
      }
      {field.type === 'date' &&
        <FormDatePicker
          dates={dates}
          field={field}
          selectedValue={selectedValue}
          parentCallback={handleDateChange}
        />
      }
      {field.type === 'group' &&
        <FormControl className="full-width">
          <FormLabel id={field.id} className="form-group-label">{field.label}</FormLabel>
          <Box className="form-group">
            {field.values.map(inputItem =>
              <div className={field.layout}>
                {inputItem.type === 'date' &&
                  <FormDatePicker
                    dates={dates}
                    field={inputItem}
                    selectedValue={inputItem?.selectedValue?.key}
                    parentCallback={handleDateChange}
                  />
                }
                {inputItem.type === 'text' &&
                  <FormTextField
                    field={inputItem}
                    selectedValue={inputItem?.selectedValue?.key}
                    parentCallback={onChangeHandler}
                  />
                }
              </div>
            )}
          </Box>
        </FormControl>
      }
      {field.type === 'blank' &&
       <Box/>
      }
    </Item>
  </Grid>
  );
}
