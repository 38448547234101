import { AppBar, Box, Toolbar } from '@mui/material';
import { Link } from 'react-router-dom'

import ICMLogo from '../../resources/icons/ICM_Logo_White.svg';
import ICMSlogan from '../../resources/elements/Building-Innovation.svg';

export default function NavBar() {
  return (
    <>
      <AppBar position="static" className="bg-primary py-4 dropshadow-sm">
        <Toolbar className="flex flex-row justify-between">
          <Link to="/home">
            <Box className="mx-4">
              <img className="h-12" src={ICMLogo} alt="The ICM Construction Logo in White" />
            </Box>
          </Link>
          <Box className="mx-4">
            <img className="h-14" src={ICMSlogan} alt="Building Innovation" />
          </Box>
        </Toolbar>
      </AppBar>
    </>
  );
}
