import React from 'react';
import { Card, Typography, Box } from '@mui/material';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

export default function CategoryCard(props) {
  const viewPermission = useSelector(state => state.user.user.viewPermission);
  const category = props.type;

  const cardContent = (
    <>
      <Card className="card-base">
        <Link to={`${props.type}-summary`}>
          <div className="card-image" style={{
            backgroundImage: `url(${props.asset})`
          }}>
            <div>
              <Box>
                <Typography className="title card-title">{props.title}</Typography>
              </Box>
            </div>
          </div>
        </Link>
      </Card>
    </>
  );

  switch (category) {
    case 'tender':
      if (viewPermission.tenderAllowed) {
        return (cardContent);
      } else {
        return (<></>);
      }
    case 'action':
      if (viewPermission.utilityAllowed) {
        return (cardContent);
      } else {
        return (<></>);
      }
    case 'users':
      if (viewPermission.utilityAllowed) {
        return (cardContent);
      } else {
        return (<></>);
      }
    default: return (<></>);
  }
}
