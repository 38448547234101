import { useEffect, useState } from 'react';
import { 
  Box, Button, FormControl, MenuItem,
  Select, TextField, Typography, Stack, LinearProgress,
  CircularProgress, FormLabel, IconButton, Tab, Tabs,
  Snackbar, Alert, Autocomplete
} from '@mui/material';
import { useSearchParams, useParams, Link } from 'react-router-dom'

// DatePicker components
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import 'dayjs/locale/en-au';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';

// Import API
import { getTender, updateTender } from '../../api/tender/index.js';
import { getAllocatableUsers } from '../../api/user';

// Import components
import GoNoGo from './GoNoGo';
import StartUp from './StartUp';
import DataEntry from './DataEntry';
import TabPanel from '../HelperComponents/tabpanel'

/**
 * The base page for displaying all the tender data
 * @param {object} props 
 * @returns 
 */
export default function TenderDetail(props) {

  const [loaded, setLoaded] = useState(false);
  const [tabValue, setTabValue] = useState(0);
  const [tenderTitle, setTenderTitle] = useState('Tender');
  const [tenderSubTitle, setTenderSubTitle] = useState('');
  const [dates, setDates] = useState({});

  const [searchparams] = useSearchParams();
  let params = useParams();

  const [tenderId, setTenderId] = useState('');
  const [tender, setTender] = useState('');
  const [goNoGo, setGoNoGo] = useState('');
  const [startUp, setStartUp] = useState('');
  const [dataEntry, setDataEntry] = useState('');

  const [allocation, setAllocation] = useState('');
  const [status, setStatus] = useState('');
  const [allocatableUsers, setAllocatableUsers] = useState([]);

  const [tenderSector, setTenderSector] = useState('');
  const [tenderCategory, setTenderCategory] = useState('');
  const [tenderType, setTenderType] = useState('');

  const [flashOpen, setFlashOpen] = useState(false);
  const [flashType, setFlashType] = useState('success');
  const [flashMessage, setFlashMessage] = useState('');

  const createStatuses = ['create', 'draft'];

  useEffect(() => {
    setLoaded(false);
    // Check and update the slides
    const slide = searchparams.get('slide')
    setPreselectedSlide(slide);
    fetchAllocatableUsers();
    setTenderData();
  }, [])

  /**
   * Handle the tab being changed
   * 
   * @param {DOMEvent} event The dom event of the tab changing
   * @param {int} newValue The new tab selected
   */
  function handleTabChange(event, newValue) {
    setTabValue(newValue);
    let newSlideUrl = '?slide=';
    switch (newValue) {
      case 0:
        newSlideUrl += 'gng';
        break;
      case 1:
        newSlideUrl += 'su';
        break;
      case 2:
        newSlideUrl += 'de';
        break;
      default:
        break;
    }
    window.history.pushState(null, '', newSlideUrl);
  }

  /**
   * Set the allocation of a tender
   * 
   * @param {DOMEvent} event The onChange event
   */
  function handleAllocation(event) {
    const key = event.target.value;
    
    setAllocation(key);
  }

  /**
   *  Update the status of the Tender
   * 
   * @param {DOMEvent} event The onChange event
   */
  function handleStatus(event) {
    const key = event.target.value;
    setStatus(key);
  }

  /**
   * Check whether the status (got from tender id) needs to trigger
   * formatting of the webpage. 
   * 
   * @param {string} tenderId The id of the tender (or create / draft)
   * @returns boolean The condition of if the status is in create mode
   */
  function isCreateStatus(tenderId) {
    return createStatuses.includes(tenderId);
  }

  /**
   * Check which mode the tender needs to be in.
   * Create:
   *     Do nothing (everything is already set up)
   * Update:
   *     Load in the tender and update the tender fields
   */
  function setTenderData() {
    if (! isCreateStatus(params.tenderId)) {
      // If the tender isn't in create then the Tender will need to be fetched
      // from the server
      getTender(params.tenderId).then((res) => {
        const tenderRes = res['data'][0];
        // Check if the tender has been loaded in already
        
        if (tenderRes !== tender) {
          // Set all tender values
          setTender(res['data'][0]);
          setTenderId(params.tenderId);
          setAllocation(tenderRes.allocated_id);
          setStatus(tenderRes.status);
          setDates({
            ...dates,
            ['due_date']: tenderRes.due_date,
            ['start_date']: tenderRes.start_date,
            ['due_time']: tenderRes.due_date
          });
        }

        setTenderTitle(tenderRes['client_code'] + ': ' + tenderRes['project_name']);
        setTenderSubTitle(tenderRes['client_name']);

        let goNoGoData = {};
        let startUpData = {};
        let dataEntryData = {};

        // Get the different Tender sub objects
        for (const tenderDetail in tenderRes) {
          const fieldName = tenderDetail.split('.')[1];
          if (tenderDetail.includes('GoNoGo')) {
            goNoGoData[fieldName] = tenderRes[tenderDetail];
          } else if (tenderDetail.includes('StartUp')) {
            startUpData[fieldName] = tenderRes[tenderDetail];
          } else if (tenderDetail.includes('DataEntry')) {
            dataEntryData[fieldName] = tenderRes[tenderDetail];
          }
        }

        const GngFields = JSON.parse(goNoGoData['fields']);
        if (GngFields) {
          if (GngFields['sector']) {
            setTenderSector(GngFields['sector']['key']);
          }
  
          if (GngFields['category']) {
            setTenderCategory(GngFields['category']['key']);
          }
  
          if (GngFields['sandbox-how']) {
            setTenderType(GngFields['sandbox-how']['key']);
          }
        }
        
        setGoNoGo(goNoGoData);
        setStartUp(startUpData);
        setDataEntry(dataEntryData);

        setLoaded(true);
      });
    } else {
      setLoaded(true);
    }
  }

  /**
   * Package and send all the new tender information to
   * the server to update
   */
  function updateTenderInformation() {
    const tenderData = {
      'tenderFormInput': {
        'id': params.tenderId,
        'allocated_id': allocation,
        'status': status,
        'start_date': dates['start_date'],
        'due_date': dates['due_date'],
        'due_time': dates['due_time']
      }
    };
    updateTender(params.tenderId, tenderData).then((response) => {
      if (response) {
        setFlashType('success');
        setFlashMessage(response.message);
      } else {
        setFlashType('error');
        setFlashMessage(response.error ?? 'Something went wrong, please try again.');
      }
      setFlashOpen(true);
    });
  }

  /**
   * Get all of the allocatable users 
   */
  function fetchAllocatableUsers() {
    getAllocatableUsers().then((res) => {
      setAllocatableUsers(res.data);
    });
  }

  /**
   * Set the tab value to a particular tab
   * 
   * @param {string} slide The slide to move to
   */
  function setPreselectedSlide(slide) {
    switch (slide) {
      case 'gng':
        setTabValue(0);
        break;
      case 'su':
        setTabValue(1);
        break;
      case 'de':
        setTabValue(2);
        break;
      default:
        break;
    }
  }

  /**
   * Generate the properties for the tabs
   * 
   * @param {int} index The index of the tab
   * @returns Object: The properties of the tab
   */
  function tabProps(index) {
    return {
      id: `tab-${index}`,
      'aria-controls': `tabpanel-${index}`,
    };
  }

  return (
    <>
      {/* Job Search */}
      <Box className="title-box">
        <Stack direction="row">
          {! loaded ?
          (
            <Box className="loading-linear-container">
              <LinearProgress className="loading-linear" />
            </Box>
          ) : (
            <>
              <Link to={`/home/tender-summary`}>
                <IconButton aria-label="go back" size="large">
                  <ArrowBackIcon />
                </IconButton>
              </Link>
              <Box className="tender-box">
                <Typography className="title text text-padding tender-title">{tenderTitle}</Typography>
                <Typography className="secondary-title text text-padding tender-title">{tenderSubTitle}</Typography>
                {(! isCreateStatus(params.tenderId) && status !== 'draft') && (
                  <Stack direction="column">
                    <Box className="tender-details">  
                      <Stack direction="row">
                        <Box>
                          <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-au">
                            <FormLabel id="start_date" className="form-label">Start Date</FormLabel>
                            <DatePicker
                              value={dates['start_date']}
                              onChange={(newValue) => {
                                setDates({ ...dates, ['start_date']: newValue })
                              }}
                              renderInput={(props) => {
                                let inputProps = props.inputProps;
                                inputProps = { ...inputProps, className: "date-input" };
                                props.inputProps = inputProps;
                                return <TextField labelid="start_date"
                                  name="start_date"  {...props} size="small" helperText={null} />
                              }}
                            />
                          </LocalizationProvider>
                        </Box>
                        <Box>
                          <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-au">
                            <FormLabel id="due_date" className="form-label">Due Date</FormLabel>
                            <DatePicker
                              value={dates['due_date']}
                              onChange={(newValue) => setDates({ ...dates, ['due_date']: newValue })}
                              renderInput={(props) => {
                                let inputProps = props.inputProps;
                                inputProps = { ...inputProps, className: "date-input" };
                                props.inputProps = inputProps;
                                return <TextField labelid="due_date"
                                  name="due_date"  {...props} size="small" helperText={null} />
                              }}
                            />
                          </LocalizationProvider>
                        </Box>
                        <Box>
                          <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-au">
                            <FormLabel id="due_time" className="form-label">Due Time</FormLabel>
                            <TimePicker
                              labelid="due_time"
                              name="due_time"
                              value={dates['due_time']}
                              onChange={(newValue) => setDates({ ...dates, ['due_time']: newValue })}
                              renderInput={(params) =>
                                <TextField
                                  {...params}
                                />
                              }
                            />
                          </LocalizationProvider>
                        </Box>
                      </Stack> 
                      <Box className="stack-divider"/>
                      <Box>
                        <FormControl className="form-label">
                          <FormLabel id="allocation">Allocation</FormLabel>
                          <Select
                            labelid="allocation"
                            name="allocation"
                            onChange={handleAllocation}
                            value={allocation}
                            className="select"
                            renderValue={(selected) => {
                              const allocated = allocatableUsers.find(o => o.id === allocation);
                              if (allocated) {
                                return allocated.name;
                              } else if (allocation !== '') {
                                return allocation;
                              } else {
                                return '';
                              }
                            }}
                          >
                            <MenuItem value="">
                              <em>None</em>
                            </MenuItem>
                            {allocatableUsers.map((user) => {
                              return <MenuItem key={user.id} value={user.id}>
                                <em>{user.name ? user.name : user.email}</em>
                              </MenuItem>
                            })}
                          </Select>
                        </FormControl>
                      </Box>
                      <Box>
                        <FormControl className="form-label">
                          <FormLabel id="status">Tender Status</FormLabel>
                          <Select
                            labelid="status"
                            name="status"
                            onChange={handleStatus}
                            value={status}
                            className="select"
                          >
                            <MenuItem value=""><em>None</em></MenuItem>
                            <MenuItem value="wip">WIP</MenuItem>
                            <MenuItem value="submitted">Submitted</MenuItem>
                            <MenuItem value="won">Won</MenuItem>
                            <MenuItem value="lost">Lost</MenuItem>
                            <MenuItem value="withdrawn">Withdrawn</MenuItem>
                            <MenuItem value="client-cancelled">Client Cancelled</MenuItem>
                            <MenuItem value="on-hold">On hold</MenuItem>
                            <MenuItem value="other">Other - Add Note</MenuItem>
                          </Select>
                        </FormControl>
                      </Box>
                      <Button
                        type="submit"
                        variant="contained"
                        className="btn-primary btn-update"
                        onClick={updateTenderInformation}
                      >
                        Update
                      </Button>
                    </Box>
                    <Box className="tender-details">
                      <Stack direction="row">
                        <TextField
                          className="text-field"
                          disabled
                          label="Sector"
                          defaultValue={tenderSector}
                        />
                         <TextField
                          className="text-field"
                          disabled
                          label="Category"
                          defaultValue={tenderCategory}
                        />
                        <TextField
                          className="text-field"
                          disabled
                          label="Type"
                          defaultValue={tenderType}
                        />
                      </Stack>
                    </Box>
                  </Stack>
                )}
                </Box>
            </>
          )}
        </Stack>

        {(! isCreateStatus(tenderId) && status !== 'draft')  &&
          <Box className="tab-panel">
            {/* Tabs */}
            <Box>
              <Box>
                <Tabs value={tabValue} onChange={handleTabChange} aria-label="tender tabs">
                  <Tab label="Go-No-Go" {...tabProps(0)} />
                  {! isCreateStatus(params.tenderId) &&
                    <Tab label="Startup" {...tabProps(1)} />
                  }
                  {! isCreateStatus(params.tenderId) &&
                    <Tab label="Data Entry" {...tabProps(2)} />
                  }
                </Tabs>
              </Box>
            </Box>
          </Box>
        }
      </Box>
      {! loaded &&
        <Box className="tender-loader">
          <CircularProgress />
        </Box>
      }
      <>
        {(! isCreateStatus(tenderId) && status !== 'draft') &&
          <Box className={"selected-submission".concat(loaded ? '' : ' hidden')}>
            <TabPanel value={tabValue} index={0}>
              <GoNoGo
                key={[goNoGo, tender]}
                goNoGoForm={goNoGo}
                tenderInformation={tender}
                tenderSubmit={updateTenderInformation}
              ></GoNoGo>
            </TabPanel>
            <TabPanel value={tabValue} index={1}>
              <StartUp
                key={StartUp}
                startUpForm={startUp}
                tenderInformation={tender}
                tenderSubmit={updateTenderInformation}
              ></StartUp>
            </TabPanel>
            <TabPanel value={tabValue} index={2}>
              <DataEntry
                key={DataEntry}
                DataEntryForm={dataEntry}
                tenderInformation={tender}
                tenderSubmit={updateTenderInformation}
              ></DataEntry>
            </TabPanel>
          </Box>
        }
        {isCreateStatus(tenderId) &&
          <GoNoGo></GoNoGo>
        }
        {status === 'draft' &&
          <GoNoGo
            key={[goNoGo, tender]}
            goNoGoForm={goNoGo}
            tenderInformation={tender}
            tenderSubmit={updateTenderInformation}
          ></GoNoGo>
        }
      </>
      <Snackbar open={flashOpen} autoHideDuration={4000} onClose={() => setFlashOpen(false)}>
        <Alert className="flash" onClose={() => setFlashOpen(false)} severity={flashType}>
          {flashMessage}
        </Alert>
      </Snackbar>
    </>
  )
}
