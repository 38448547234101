import React, {
  useState, useEffect,
  useReducer, useCallback
} from 'react';
import {
  Box, FormControl, Button, FormLabel,
  Select, MenuItem, Grid, Typography,
  Divider, Stack, Dialog, DialogTitle, 
  DialogContent, DialogActions, DialogContentText,
  LinearProgress, Snackbar, Alert, CircularProgress,
  IconButton
} from '@mui/material';
import moment from 'moment';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import DoneIcon from '@mui/icons-material/Done';
import CancelIcon from '@mui/icons-material/Close';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import {
  GridRowModes,
  DataGrid,
  GridActionsCellItem,
  GridRowEditStopReasons,
} from '@mui/x-data-grid';
import { randomId } from '@mui/x-data-grid-generator';

import { useDropzone } from 'react-dropzone';

// Import API
import { createStartUp } from '../../api/tender';
import { getUsers } from '../../api/user';
import { getActionsForStartUp, saveActionsForStartUp } from '../../api/action';
import {
  uploadActionAttachment, getActionAttachment,
  getActionAttachments, deleteActionAttachment
} from '../../api/action';

// Import Common fields
import getFieldHTML from './getFieldHtml';

export default function StartUp(props) {
  const startUpFieldsBase = require('../../resources/data/startup.json');

  const [loaded, setLoaded] = useState(false);

  const [startUpFormInput, setStartUpFormInput] = useReducer(
    (state, newState) => ({ ...state, ...newState }), {}
  );

  const [dates, setDates] = useState({});
  const [startUpFields, setStartUpFields] = useState([])
  const [submissionStatus, setSubmissionStatus] = useState('')

  /**
   * Action List
   */
  const [actionList, setActionList] = useState([]);
  const [actionFetched, setActionFetched] = useState(false);
  const [rowModesModel, setRowModesModel] = useState({});
  const [userList, setUserList] = useState([]);
  const [activeActionId, setActiveActionId] = useState('');
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  
  const [flashOpen, setFlashOpen] = useState(false);
  const [flashType, setFlashType] = useState('success');
  const [flashMessage, setFlashMessage] = useState('');

  const [permissionAction, setPermissionAction] = useState(true);

  const [actionAttachments, setActionAttachments] = useState([]);
  const [deleteAttachmentConfirmationOpen, setDeleteAttachmentConfirmationOpen] = useState(false);
  const [activeAttachment, setActiveAttachment] = useState('');
  const [uploadOpen, setUploadOpen] = useState(false);
  const [files, setFiles] = useState([]);
  const [loading, setLoading] = useState(false);

  const onDrop = useCallback(newFiles => {
    actionList.forEach(action => {
      if (action.id === activeActionId) {
        action.files = newFiles;
      }
    });
    setFiles(prevFiles => [...prevFiles, ...newFiles]);
  }, [activeActionId, actionList]);

  const { getRootProps, getInputProps } = useDropzone({ onDrop });


  useEffect(() => {
    setLoaded(false);
    // Do All loading within setlLoaded
    loadStartupFields();
    setLoaded(true);
    getAllUsers();
    getActions(props.startUpForm.id);
  }, [props])

  /**
   * Handle the input changing for a field in the form
   * 
   * @param {DOMEvent} event The change event for a field 
   */
  function handleInput(event) {
    const name = event.target.name;
    const key = event.target.value;
    updateFieldValue(name, key);
  }

  /**
   * Set the new date value on a particular field
   * 
   * @param {Date} newDate The changed date value
   * @param {Object} field The field the date is coming from
   */
  function handleDateChange(newDate, field) {
    setDates({ ...dates, [field.id]: newDate != null ? newDate.toJSON() : ''});
  }

  /**
   * Handle the status of the form submision changing
   * 
   * @param {DOMEvent} event The change event for the object Submission status
   */
  function handleStatus(event) {
    const key = event.target.value;
    setSubmissionStatus(key);
  }

  /**
   * Handle the submission event
   * 
   * @param {DOMEvent} event The onsubmit event
   */
  async function handleSubmit(event) {
    event.preventDefault();

    props.tenderSubmit();

    const datesData = dates;
    for (const date in datesData) {
      startUpFormInput[date] = datesData[date];
    }
    const startUpData = {
      'startUpFormInput': {
        'tender_id': props.tenderInformation.id,
        'status': submissionStatus,
        'fields': startUpFormInput
      }
    };
    
    createStartUp(startUpData);

    // update actions
    let allActionsValid = true
    for (let index = 0; index < actionList.length; index++) {
      const element = actionList[index];
      if (! element['user_id']) {
        allActionsValid = false;
      }
    }

    if (! allActionsValid) {
      setFlashType('error');
      setFlashMessage('Please fill in an assignee.');
      setFlashOpen(true);
    } else {
      const actionData = {
        'start_up_id': props.startUpForm.id,
        'actions': actionList
      };
      saveActionsForStartUp(actionData).then(async (res) => {
        for (let index = 0; index < actionList.length; index++) {
          const action = actionList[index];

          if (action.files && action.files.length !== 0 && action.isNew) {
            await uploadData(action.files, action.id);
          }
        }
        window.location.reload();
      });
    }
  }

  /**
   * Handle adding a new blank object to the action summary table
   */
   const handleAddAction = () => {
    const id = randomId();
    setActionList((oldActionList) => [...oldActionList, { 
      id,
      object_type: 'Tender',
      associated_object_id: props.tenderInformation.id,
      associated_subobject_id: props.startUpForm.id,
      user_id: '', 
      description: 'Please update description', 
      due_date: new Date(),
      status: 'Draft',
      isNew: true
    }]);
    setRowModesModel((oldModel) => ({
      ...oldModel,
      [id]: { mode: GridRowModes.Edit, fieldToFocus: 'user_id' },
    }));
  }

  /**
   * handle the stopping of editing the row
   * 
   * @param {object} params The params of the object event
   * @param {DOMEvent} event The event
   */
  const handleRowEditStop = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  /**
   * Handle the change of mode for the rows
   * 
   * @param {object} newRowModesModel The object of the rows
   */
  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  /**
   * Set the row mode to edit
   * 
   * @param {string} id The id of the row / action
   */
  const handleEditClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  /**
   * Set the row mode to view (after saving)
   * 
   * @param {string} id The id of the row / action
   */
  const handleSaveClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  /**
   * Handle deleting an action from the table
   * 
   * @param {string} id The Id of the action
   */
  const handleDeleteClick = (id) => () => {
    setActionList(actionList.filter((action) => action.id !== id));
    setConfirmationOpen(false);
  };

  /**
   * Cancel editing an action
   * 
   * @param {string} id The Id of the action
   */
  const handleCancelClick = (id) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedAction = actionList.find((action) => action.id === id);
    if (editedAction.isNew) {
      setActionList(actionList.filter((action) => action.id !== id));
    }
  };

  /**
   * Update the field value in the form input
   * 
   * @param {string} name The name of the field
   * @param {string} key The key of the field
   */
  function updateFieldValue(name, key) {
    let value = 0;

    setStartUpFormInput({ [name]: { 'key': key, 'value': value } });
  }

  /**
   * Load all the startup fields from the passed through Startup object
   * 
   */
  function loadStartupFields() {
    // Deep copy the base attributes
    const startUpFieldsBaseData = JSON.parse(JSON.stringify(startUpFieldsBase))['data'];
    if (props.startUpForm.fields && props.tenderInformation.id) {
      const startUpSubmittedFields = JSON.parse(props.startUpForm.fields);
      // Iterate through the base fields and update the values from the
      // existing start up
      startUpFieldsBaseData.forEach(element => {
        if (element.type === 'group') {
          element.values.forEach(subfield => {
            if (Object.keys(startUpSubmittedFields).includes(subfield.id)) {
              updateFieldValue(subfield.id, startUpSubmittedFields[subfield.id]['key']);
              subfield.selectedValue = startUpSubmittedFields[subfield.id];
            }
          })
        } else if (element.type === 'date') {
          setDates(previousInputs => ({ ...previousInputs, [element.id]: startUpSubmittedFields[element.id] }));
        } else {
          if (Object.keys(startUpSubmittedFields).includes(element.id)) {
            updateFieldValue(element.id, startUpSubmittedFields[element.id]['key']);
            element.selectedValue = startUpSubmittedFields[element.id];
          }
        }
      });
      setStartUpFields(startUpFieldsBaseData);
      setSubmissionStatus(props.startUpForm.status);
    } else {
      setStartUpFields(startUpFieldsBaseData);
    }
  }

  /**
   * Get all the users and update the user list
   */
  const getAllUsers = async () => {
    await getUsers().then((res) => {
      let users = res['data'];
      // Make sure its an array
      if (users === undefined) {
        return;
      }
      if (! Array.isArray(users)) {
        users = [users];
      }
      if (users !== userList) {
        setUserList(users);
      }
    });
  }

  /**
   * Get all the users and update the user list
   */
  const getActions = async (startId) => {
    if (! startId || startId === '') {
      setActionList([]);
      setActionFetched(true);
      return;
    }
    await getActionsForStartUp(startId).then((res) => {
      let actions = res['data'];

      // Make sure its an array
      if (! Array.isArray(actions)) {
        actions = [actions];
      }
      if (actions !== actionList) {
        setActionList(actions);
        setActionFetched(true);
      }
    }).catch((error) => {
      setPermissionAction(false);
      setActionFetched(true);
    });
  }

  /**
   * Render all of the Startup fields into HTML
   * 
   * @returns Array[HTMLObject] THe rendering of all the fields
   */
  function renderStartUpItems() {
    return startUpFields.map(field =>
      getFieldHTML(field, handleInput, handleDateChange, dates)
    );
  }

  /**
   * The process of saving or updating an action to the backend
   * 
   * @param {Object} newAction The new action to save or update
   * @returns Object The new action that was processed
   */
  const processRowUpdate = (newAction) => {
    const updatedAction = { ...newAction };
    setActionList(actionList.map((action) => (action.id === newAction.id ? updatedAction : action)));
    return updatedAction;
  };

  /**
   * Format the date
   * 
   * @param {string} date 
   * @returns string: the displayable date
   */
  const formatDate = (date) => {
    if (date) {
      return moment(date).format('DD/MM/YYYY');
    } else {
      return '-';
    }
  }

    /**
   * Remove the file from the list of files
   * 
   * @param {object} file 
   */
    const remove = (file) => {
      const newFiles = [...files];
      const activeAction = actionList.find((action) => action.id === activeActionId);
      newFiles.splice(newFiles.indexOf(file), 1);
      setFiles(newFiles);
      activeAction.files = newFiles;
      if (newFiles.length === 0) {
        setUploadOpen(false);
      }
    };
  
    const acceptedFileItems = files.map((file, i) => (
      <li key={file.path}>
        {file.name} : {file.size} bytes.
        <button className="removeButton" type="button" onClick={() => remove(i)}>Remove</button>
      </li>
    ));
  
    /**
     * Get an attachment file from the backend (Returned is the blob)
     * and download it or show it to the user (for PDF)
     * 
     * @param {string} fileId The Id of the file
     * @param {object} attachment The attachment object containing the metadata of the file
     */
    function getActionFile(fileId, attachment) {
      getActionAttachment(fileId).then((data) => {
        if (attachment.filetype === 'application/pdf') {
          const url = window.URL.createObjectURL(new Blob([data], { type: 'application/pdf' }));
          const pdfWindow = window.open('/');
          pdfWindow.location.href = url;
        } else {
          const url = window.URL.createObjectURL(new Blob([data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', attachment.filename);
          document.body.appendChild(link);
          link.click();
          link.parentNode.removeChild(link);
        }
      })
    }
  
    /**
    * Handle the upload file action. Depending on the action state either the files will
    * be uploaded immediately or the actions will be cached to be uploaded when the action
    * is created (if the action is new)
    * 
    * If the action is new (isNew == true) then the action in the DB hasn't been created.
    * To not create potentially parentless files in S3 we wait to upload the files and give
    * a warning to the user
    */
    function handleUploadingData() {
      const activeAction = actionList.find((action) => action.id === activeActionId)
  
      if (activeAction.isNew) {
        activeAction.files = files;
        setFlashType('warning');
        setFlashMessage('Files will be uploaded when action is saved');
        setFlashOpen(true);
      } else {
        uploadData().then(() => {
          activeAction.files = [];
        });
      }
      setUploadOpen(false);
    }
  
    /**
     * Send the attachments to be uploaded to S3 via the backend
     * 
     * @param {Array<fileObject>} filesToUpload 
     */
    async function uploadData(filesToUpload = [], actionId = '') {
      if (filesToUpload.length === 0) {
        filesToUpload = files;
      }

      if (actionId === '') {
        actionId = activeActionId
      }
      if (filesToUpload.length !== 0) {
        const formDataObj = new FormData();
        formDataObj.append('actionInput', JSON.stringify({ 'id': actionId }))
        for (let i = 0; i < filesToUpload.length; i++) {
          formDataObj.append('files', filesToUpload[i]);
          if (i === filesToUpload.length - 1) {
            uploadActionAttachment(formDataObj).then((res) => {
              return Promise((resolve, reject) => {
                if (res.status === 200) {
                  resolve(res);
                } else {
                  reject(res);
                }
              })
            })
          }
        }
      }
    }
  
    /**
     * begin the process of deleting an attachment
     * 
     * @param {string} fileId The Id of the file
     */
    function deleteActionFile(fileId) {
      setActiveAttachment(fileId);
      setDeleteAttachmentConfirmationOpen(true);
    }
  
    /**
     * Handle the confirmation of deleting a file being clicked
     * this will delete the attachment from the DB and S3
     */
    async function handleDeleteAttachmentClick() {
      const fileId = activeAttachment;
  
      await deleteActionAttachment(fileId).then((data) => {
        const attachment = actionAttachments.find((attachment) => attachment.id === fileId)
        actionAttachments.splice(actionAttachments.indexOf(attachment), 1);
        setActionAttachments(actionAttachments);
        
        setFlashType('success');
        setFlashMessage('File has been deleted');
        setFlashOpen(true);
      }).catch((err) => {
        setFlashType('error');
        setFlashMessage('File was not deleted properly');
        setFlashOpen(true);
      })
      setDeleteAttachmentConfirmationOpen(false)
    }
  
    /**
     * Handle the closing of the review modal
     */
    function handleClose() {
      setUploadOpen(false);
    }

  const columns = [
    { 
      field: 'user_id', 
      headerName: 'Assignee', 
      width: 190,
      flex: 0.5,
      editable: true,
      type: 'singleSelect',
      valueFormatter: (params) => {
        if (params.value == null) {
          return '';
        }
        const userId = params.value;
        const selectedUser = userList.find(user => user.id === userId);
        if (! selectedUser) {
          return '';
        }
        return selectedUser.name;
      },
      valueOptions: userList.map(user => { 
        if (user.id) {
          return user.id;
        } else {
          return user.email;
        }
      }),
    },
    {
      field: 'description',
      headerName: 'Description',
      width: 340,
      flex: 1,
      editable: true,
    },
    {
      field: 'due_date',
      headerName: 'Due Date',
      type: 'date',
      width: 120,
      flex: 0.5,
      editable: true,
      valueFormatter: (params) => {
        return formatDate(params.value);
      }
    },
    {
      field: 'status',
      headerName: 'Status',
      width: 80,
      flex: 0.5,
      editable: true,
      type: 'singleSelect',
      valueOptions: ['Draft', 'WIP', 'Completed'],
    },
    {
      field: 'attachments',
      type: 'actions',
      headerName: 'Attachments',
      width: 120,
      cellClassName: 'actions',
      getActions: ({ id, row }) => {
        return [
          <GridActionsCellItem
            icon={<UploadFileIcon />}
            label='upload'
            sx={{
              color: 'primary.main',
            }}
            onClick={() => {
              setActiveActionId(id);
              if (row.files) {
                setFiles(row.files);
              } else {
                setFiles([]);
              }

              if (row.isNew) {
                setActionAttachments([]);
                setUploadOpen(true);
              } else {
                getActionAttachments(id).then((res) => {
                  setActionAttachments(res['data']);
                  setUploadOpen(true);
                })
              }
            }}
          />
        ]
      }
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      width: 100,
      cellClassName: 'actions',
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<DoneIcon />}
              label="Save"
              sx={{
                color: 'primary.main',
              }}
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
            />,
          ];
        }

        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(id)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            onClick={() => {
              setActiveActionId(id);
              setConfirmationOpen(true);
            }}
            color="inherit"
          />,
        ];
      },
    },
  ];

  return (
    <>
      <form onSubmit={handleSubmit} className={"step-form".concat(loaded ? '' : ' hidden')}>
        <Typography className="secondary-title text">Start Up Information</Typography>
        <Grid container spacing={2} className="field-grid">
          {renderStartUpItems()}
        </Grid>
        <Box className="step-status step-status--startup">
          <FormControl className="form-label status-label">
            <FormLabel id="status">Start Up Status</FormLabel>
            <Select
              labelId="status"
              name="status"
              onChange={handleStatus}
              value={submissionStatus}
            >
              <MenuItem key="na" value="na">
                <em>NA</em>
              </MenuItem>
              <MenuItem key="wip" value="wip">
                <em>WIP</em>
              </MenuItem>
              <MenuItem key="completed" value="completed">
                <em>Completed</em>
              </MenuItem>
            </Select>
          </FormControl>
        </Box>
        <Divider>Actions</Divider>
        {actionFetched ? (
          <>
            <Stack direction="row" spacing={1} sx={{ mb: 1 }}>
              <Button 
                className="btn-primary"
                variant="contained" 
                startIcon={<AddIcon />}
                onClick={handleAddAction
              }>
                Add Action
              </Button>
            </Stack>
            <Box className="action-table"> 
              <DataGrid
                autoHeight
                experimentalFeatures={{ newEditingApi: true }}
                columnBuffer={columns.length}
                rows={actionList}
                columns={columns}
                editMode="row"
                rowModesModel={rowModesModel}
                onRowModesModelChange={handleRowModesModelChange}
                onRowEditStop={handleRowEditStop}
                processRowUpdate={processRowUpdate}
                pageSize={10}
                rowsPerPageOptions={[10]}
                components={{
                  NoRowsOverlay: () => (
                    <Stack height="100%" alignItems="center" justifyContent="center">
                      {permissionAction ? (
                        <p>No Rows</p>
                      ) : (
                        <p>Unauthorized to see Actions</p>
                      )}
                    </Stack>
                  )
                }}
              />
            </Box>
          </>
        ) : (
          <LinearProgress sx={{ mb: 1 }} />
        )}
        <Box className="step-submission">
          <Button
            className="btn-primary"
            type="submit"
            variant="contained"
          >
            Submit
          </Button>
        </Box>
      </form>
      <Dialog open={confirmationOpen} >
        <DialogTitle>Confirmation</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure about deleting this action?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={() => {setConfirmationOpen(false);}}>
            Cancel
          </Button>
          <Button onClick={() => {handleDeleteClick(activeActionId);}}>Ok</Button>
        </DialogActions>
      </Dialog>
      {/* Upload attachment dialog */}
      <Dialog open={uploadOpen} >
        <DialogTitle>Add Attachments</DialogTitle>
        <DialogContent>
          <Box className="file-list">
            <h4>Uploaded Files:</h4>
            <ul className="uploaded-files-list">
              {actionAttachments.map(attachment =>
                <li
                  key={attachment.filename}
                  value={attachment.filename}
                >
                  <button
                    className="file-link"
                    onClick={() => getActionFile(attachment.id, attachment)}
                  >
                    {attachment.filename}
                  </button>
                  <IconButton
                    color="error"
                    onClick={() => {
                      deleteActionFile(attachment.id)
                    }}
                  >
                    <CancelIcon />
                  </IconButton>
                </li>
              )}
            </ul>
          </Box>
          <Box className="fileupload">
            <div {...getRootProps({ className: "dropzone" })}>
              <input {...getInputProps()} />
              <p>Drag & Drop files here, or click to select.</p>
            </div>
          </Box>
          <Box className="file-list">
            <h4>Files Attached:</h4>
            {acceptedFileItems.length ?
              <ul>{acceptedFileItems}</ul>
              : <p>There are currently no files attached.</p>
            }
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={(event) => handleUploadingData()}>Upload Data</Button>
        </DialogActions>
      </Dialog>
      {/* Delete attachment popup */}
      <Dialog open={deleteAttachmentConfirmationOpen} >
        <DialogTitle>Delete Confirmation</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure about deleting this action attachment?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {loading ?
            <CircularProgress size='2rem' />
            :
            <>
              <Button autoFocus onClick={() => {
                setDeleteAttachmentConfirmationOpen(false);
              }}>
                Cancel
              </Button>
              <Button onClick={() => {
                handleDeleteAttachmentClick()
              }}>Ok</Button>
            </>
          }
        </DialogActions>
      </Dialog>
      <Snackbar open={flashOpen} autoHideDuration={4000} onClose={() => setFlashOpen(false)}>
        <Alert className="flash" onClose={() => setFlashOpen(false)} severity={flashType}>
          {flashMessage}
        </Alert>
      </Snackbar>
    </>
  );
}
