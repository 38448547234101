
import React from 'react';
import { FormControl, OutlinedInput, FormLabel, FormHelperText } from '@mui/material';

/**
 * The common Text field component
 */
class FormTextField extends React.Component {

  /**
   * The On Change function to execute when the Text Field changes
   * 
   * @param {object} event The event of Text field on change
   */
  onChange = (event) => {
    // Call the parent callback function
    this.props.parentCallback(event);
    event.preventDefault();
  }

  /**
   * React seems to have an inherent bug where some registers of backspace
   * don't register. Therefore need to add a handler for backspace press
   * to push through the event anyway
   * 
   * @param {object} event The dom object event
   */
  handleBackspace = (event) => {
    if (event.keyCode === 8) {
      this.onChange(event);
    }
  };

  render() {
    return (
      <FormControl className="form-label">
        <FormLabel id={this.props.field.id} >{this.props.field.label}</FormLabel>
        <OutlinedInput
          id={this.props.field.id}
          name={this.props.field.id}
          defaultValue={this.props.selectedValue}
          label={this.props.field.label}
          onChange={this.onChange}
          onKeyUp={this.handleBackspace}
          multiline
          error={this.props.field.isError}
          maxRows={4}
        />
        {this.props.field.isError &&
          <FormHelperText className="error">{this.props.field.errorText}</FormHelperText>
        }
      </FormControl>
    );
  }
}
export default FormTextField;