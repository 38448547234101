import React, { useEffect, useState } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { CircularProgress } from '@mui/material';

import { useDispatch } from 'react-redux';
import { getUserSuccess, getUserPending, getUserError } from './redux/features/user/userSlice';
import { getUser } from './api/user';

// Component Imports:
import Login from './components/Login'
import LoginFailed from './components/Login/login-failed';
import Logout from './components/Login/logout';
import NavBar from './components/HelperComponents/navbar';
import LandingPage from './components/Landing';
import TenderSummary from './components/TenderSummary';
import ActionSummary from './components/ActionSummary';
import TenderDetail from './components/TenderDetail';
import UserSummary from './components/UserSummary';

function App(props) {
  const dispatch = useDispatch();
  // Get if the user is authenticated or if the user is an admin
  const [loading, setLoading] = useState(true);
  const [authenticated, setAuthenticated] = useState(false);
  const [admin, setAdmin] = useState(false);

  /**
   * Fetch a new user state from the Backend
   * will need to do this every fresh page load
   */
  const checkAuthenticated = async () => {
    // Set pending action to pending while waiting on request.
    dispatch(getUserPending());
    return await getUser().then((res) => {
      if (res.authenticated) {
        // If the user is successfully authenticated via passport, set the success action to the users credentials:
        dispatch(getUserSuccess(res.user));
      } else {
        // If an error occurs set the user action to an error state:
        dispatch(getUserError());
      }
      setAuthenticated(res.authenticated);
      setAdmin(res.user.type === 'admin');
      setLoading(false);
    }).catch((err) => {
      // If any errors, reload to the base page
      window.location.assign('/');
    });
  }

  checkAuthenticated();

  return (<>
    {loading ? (
      <CircularProgress />
    ) : (
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/login/failed" element={<LoginFailed />} />
        <Route path="/logout" element={<Logout />} />
        <Route path="/home"
          element={authenticated ? (
            <>
              <NavBar />
              <LandingPage user={props.user} />
            </>
          ) : (
            <Navigate to="/" state={{'origin_url': window.location.href}} />
          )}
        />
        <Route path="home/tender-summary"
          element={authenticated ? (
            <>
              <NavBar />
              <TenderSummary />
            </>
          ) : (
            <Navigate to="/" state={{'origin_url': window.location.href}} />
          )}
        />
        <Route path='home/tenders/:tenderId'
          element={authenticated ? (
            <>
              <NavBar />
              <TenderDetail />
            </>
          ) : (
            <Navigate to="/" state={{'origin_url': window.location.href}} />
          )}
        />
        <Route path="home/action-summary"
          element={
            <>
              {authenticated && admin ? (
                <>
                  <NavBar />
                  <ActionSummary />
                </>
              ) : (
                <>
                  {authenticated ? (
                    <Navigate to="/home/" />
                  ) : (
                    <Navigate to="/" state={{'origin_url': window.location.href}} />
                  )}
                </>
              )}
            </>
          }
        />
        <Route path="home/users-summary"
          element={
            <>
              {authenticated && admin ? (
                <>
                  <NavBar />
                  <UserSummary />
                </>
              ) : (
                <>
                  {authenticated ? (
                    <Navigate to="/home/" />
                  ) : (
                    <Navigate to="/" state={{'origin_url': window.location.href}} />
                  )}
                </>
              )}
            </>
          }
        />
      </Routes>
    )}
  </>
  )
}

export default App;
