import { Box, Typography, Divider, Button } from '@mui/material';

// Asset Imports:
import IconElement from '../../resources/elements/Building-Innovation.svg';
import ICMLogo from '../../resources/icons/ICM_Logo_stacked.svg';

export default function Login() {
  return (
    <div>
      <div className="login-background">
        <Box className="m-auto flex flex-col lg:flex-row">
          <Box className="bg-background-secondary rounded-3xl max-w-xs lg:max-w-loginBox lg:p-3 flex flex-col justify-center items-center drop-shadow-md">
            <img className="h-36 py-8 mx-4 fill-primary" src={ICMLogo} alt="ICM Construction Logo" />
            <Divider className="px-32 lg:px-48" />
            <Typography className="title text my-4">Login Failed</Typography>
            <Typography className="text px-12">Your internal Microsoft account couldn't be verified. If you believe this is a mistake please contact the site administrator or try again.</Typography>
            <Button
              onClick={() => { window.location.href = '/' }}
              className="input-field btn-primary" variant="contained">
              Try Again
            </Button>
            <Box className="flex flex-col lg:flex-row">
              <Typography className="pt-4 lg:py-7 italic text-center lg:text-justify text-desktop-login-secondary text-primary">For enquires please contact:</Typography>
              <Typography className="mb-6 pb-6 lg:py-7 italic text-center lg:text-justify font-semibold text-desktop-login-secondary text-primary">info@icmco.com.au</Typography>
            </Box>
          </Box>
          <Box className="side-logo">
            <div>
              <img className="side-logo" src={IconElement} alt="Business Innovation Logo" />
            </div>
          </Box>
        </Box>
      </div>
    </div>
  );
}