import { Typography, Box } from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';

export default function WelcomeCard(props) {
  const user = useSelector(state => state.user.user);

  return (
    <Box className="title-box text-padding">
      <Box>
        <Typography className="welcome-title title text">
          {`Welcome, ${user.name}.`}
        </Typography>
        <Typography className="welcome-help text">
          {`This is the new ICM Reporting Utility.`}
        </Typography>
        <Typography className="welcome-desc text">
          To get started with the new ICM Reporting Utility select an option from the cards below. If you cannot see the correct utility please contact the site administrator.
        </Typography>
      </Box>
    </Box>
  );
}
