import { useEffect, useState } from 'react';
import {
  Box, Button, Typography, Switch, Checkbox, FormGroup,
  FormControlLabel, Dialog, DialogActions, DialogContent,
  DialogTitle, TextField, Snackbar, Alert, Grid,
  DialogContentText, CircularProgress, 
} from '@mui/material';

import {
  GridRowModes,
  DataGrid,
  GridActionsCellItem,
  GridRowEditStopReasons,
} from '@mui/x-data-grid';

import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';

import {
  getUsers, signUp, updateUsers, deleteUsers
} from '../../api/user';

export default function UserSummary() {
  const [userList, setUserList] = useState([]);
  const [newEmail, setNewUserEmail] = useState('');
  const [newPassword, setNewUserPassword] = useState('');
  const [open, setOpen] = useState(false);

  const [rowModesModel, setRowModesModel] = useState({});
  const [activeUser, setActiveUser] = useState('');
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
  const [saveConfirmationOpen, setSaveConfirmationOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const [flashOpen, setFlashOpen] = useState(false);
  const [flashType, setFlashType] = useState('success');
  const [flashMessage, setFlashMessage] = useState('');

  // The definitiion of how the columns should be laid out
  const columns = [
    {
      field: 'email', headerName: 'Email', width: 130, flex: 1
    },
    {
      field: 'name',
      headerName: 'Name',
      width: 100,
      flex: 0.7,
      editable: true
    },
    {
      field: 'type',
      headerName: 'Role',
      width: 70,
      flex: 0.7,
      editable: true,
      type: 'singleSelect',
      valueOptions: [
        { value: 'user', label: 'User' },
        { value: 'admin', label: 'Admin' }
      ]
    },
    {
      field: 'access',
      headerName: 'Access',
      width: 130,
      height: 100,
      flex: 1,
      renderCell: (params) => {
        return (
          <FormGroup>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <FormControlLabel control={
                  <Checkbox
                    onChange={(event) =>
                      handleChange(event, params.row, 'UserAccess.tenderAccess')
                    }
                    checked={params.row['UserAccess.tenderAccess']}
                  />
                } label="Tender Edit" />
              </Grid>
              <Grid item xs={6}>

                <FormControlLabel control={
                  <Checkbox
                    onChange={(event) =>
                      handleChange(event, params.row, 'UserAccess.tenderAllowed')
                    }
                    checked={params.row['UserAccess.tenderAllowed']}
                  />
                } label="Tender Access" />
              </Grid>
              <Grid item xs={6}>
                <FormControlLabel control={
                  <Checkbox
                    onChange={(event) =>
                      handleChange(event, params.row, 'UserAccess.utilityAccess')
                    }
                    checked={params.row['UserAccess.utilityAccess']}
                  />
                } label="Utility Edit" />
              </Grid>
              <Grid item xs={6}>
                <FormControlLabel control={
                  <Checkbox
                    onChange={(event) =>
                      handleChange(event, params.row, 'UserAccess.utilityAllowed')
                    }
                    checked={params.row['UserAccess.utilityAllowed']}
                  />
                } label="Utility Access" />
              </Grid>
              <Grid item xs={6}>
                <FormControlLabel control={
                  <Checkbox
                    onChange={(event) =>
                      handleChange(event, params.row, 'UserAccess.actionAccess')
                    }
                    checked={params.row['UserAccess.actionAccess']}
                  />
                } label="Action Edit" />
              </Grid>
              <Grid item xs={6}>
                <FormControlLabel control={
                  <Checkbox
                    onChange={(event) =>
                      handleChange(event, params.row, 'UserAccess.actionAllowed')
                    }
                    checked={params.row['UserAccess.actionAllowed']}
                  />
                } label="Action Access" />
              </Grid>
            </Grid>
          </FormGroup>
        );
      }
    },
    {
      field: 'tender_notify',
      headerName: 'Notify',
      width: 70,
      flex: 0.3,
      renderCell: (params) => {
        return (
          <Switch
            checked={params.row['tender_notify']}
            onChange={(event) =>
              handleChange(event, params.row, 'tender_notify')
            }
            inputProps={{ 'aria-label': 'controlled' }}
          />
        );
      }
    },
    {
      field: 'allocatable',
      headerName: 'Allocatable',
      width: 70,
      flex: 0.3,
      renderCell: (params) => {
        return (
          <Switch
            checked={params.row['allocatable']}
            onChange={(event) =>
              handleChange(event, params.row, 'allocatable')
            }
            inputProps={{ 'aria-label': 'controlled' }}
          />
        );
      }
    },
    {
      field: 'approver',
      headerName: 'Approver',
      width: 90,
      flex: 0.3,
      renderCell: (params) => {
        return (
          <Switch
            checked={params.row['approver']}
            onChange={(event) =>
              handleChange(event, params.row, 'approver')
            }
            inputProps={{ 'aria-label': 'controlled' }}
          />
        );
      }
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      width: 100,
      cellClassName: 'actions',
      getActions: ({ id, row }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;
        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon size="large" />}
              label='Save'
              sx={{
                color: 'primary.main',
              }}
              onClick={() => {
                setActiveUser(id);
                setSaveConfirmationOpen(true);
              }}
            />,
            <GridActionsCellItem
              icon={<CancelIcon size="large" />}
              label='Cancel'
              className='textPrimary'
              onClick={handleCancelClick(id)}
              color='inherit'
            />,
          ];
        }
        return [
          <GridActionsCellItem
            icon={<EditIcon size="large" />}
            label='Edit'
            className='textPrimary'
            onClick={handleEditClick(id)}
            color='inherit'
          />,
          <GridActionsCellItem
            icon={<DeleteIcon size="large" />}
            label='Delete'
            onClick={() => {
              setActiveUser(id);
              setDeleteConfirmationOpen(true);
            }}
            color='inherit'
          />,
        ];
      },
    },
  ];

  useEffect(() => {
    getAllUsers();
  }, [])

  /**
 * handle the stopping of editing the row
 * 
 * @param {object} params The params of the object event
 * @param {DOMEvent} event The event
 */
  const handleRowEditStop = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  /**
   * Handle the change of mode for the rows
   * 
   * @param {object} newRowModesModel The object of the rows
   */
  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  /**
   * Set the row mode to edit
   * 
   * @param {string} id The id of the row / user
   */
  const handleEditClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  /**
   * Handle either the user role being changed or the 
   * Access they have to individual areas
   * 
   * @param {DOMEvent} event The onChange event 
   * @param {Object} row The data for the row
   * @param {Object} field The name of the field changed
   */
  function handleChange(event, row, field) {
    setRowModesModel({ ...rowModesModel, [row.id]: { mode: GridRowModes.Edit } });

    if (field === 'type' || field === 'name') {
      row[field] = event.target.value;
    } else {
      row[field] = event.target.checked;
    }
  }

  /**
   * Set the model to be open
   */
  function handleClickOpen() {
    setOpen(true);
  }

  /**
   * Set the model to be closed
   */
  function handleClose() {
    setOpen(false);
  }

  /**
   * Get all the users and update the user list
   */
  const getAllUsers = async () => {
    await getUsers().then((res) => {
      let users = res['data'];
      // Make sure its an array
      if (! Array.isArray(users)) {
        users = [users];
      }
      if (users !== userList) {
        setUserList(users);
      }
    })
  }
  /**
   * Create a new user with a username and password
   * 
   * NOTE:
   *      This user will be created with no permissions
   */
  const createNewUser = async () => {
    signUp({'user': {
      'username': newEmail,
      'password': newPassword
    }
    }).then((res) => {
      if (res.status === 200 || ! res.error) {
        window.location.reload();
      } else {
        setFlashType('error');
        setFlashMessage(res.error ?? 'Something went wrong, please try again.');
        setFlashOpen(true);
      }
    }).catch(function (err) {
      console.log(err);
    })
    handleClose();
  }

  /**
   * Delete a user
   * @param {Object} params 
   */
  async function deleteUser(id) {
    deleteUsers(id).then((res) => {
      window.location.reload();
    });
  }

  /**
   * Handle deleting an user from the table
   * 
   * @param {string} id The Id of the user
   */
  const handleDeleteClick = (id) => async () => {
    setLoading(true);

    const response = await deleteUser(id);

    setDeleteConfirmationOpen(false);
    setLoading(false);

    if (response.data) {
      setFlashType('success');
      setFlashMessage(response.message);
    } else {
      setFlashType('error');
      setFlashMessage(response.error ?? 'Something went wrong, please try again.');
    }

    setFlashOpen(true);
  };

  /**
   * Cancel editing an user
   * 
   * @param {string} id The Id of the user
   */
  const handleCancelClick = (id) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedAction = userList.find((user) => user.id === id);
    if (editedAction.isNew) {
      setUserList(userList.filter((user) => user.id !== id));
    }
  };

  /**
  * Set the row mode to view (after saving)
  * 
  * @param {string} id The id of the row / user
  */
  const handleSaveClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  /**
   * Handle an error being thrown on row update
   * 
   * @param {object} error 
   */
  const onProcessRowUpdateError = async (error) => {
    setFlashType('error');
    setFlashMessage(error.toString() ?? 'Something went wrong, please try again.');
    setFlashOpen(true);
  }

  /**
   * The process of saving or updating an user to the backend
   * 
   * @param {Object} newUser The new user to save or update
   * @returns Object The new user that was processed
   */
  const processRowUpdate = async (newUser) => {
    setLoading(true);

    const updatedUser = { ...newUser, isNew: false };

    const response = await updateUsers(newUser);

    setSaveConfirmationOpen(false);
    setLoading(false);

    if (response.status !== 200) {
      setFlashType('success');
      setFlashMessage(response.message);
    } else {
      setFlashType('error');
      setFlashMessage(response.error ?? 'Something went wrong, please try again.');
    }

    setFlashOpen(true);
    return updatedUser;
  };

  return (
    <>
      {/* Users Title */}
      <Box className="title-box">
        <Typography className="title text user-title text-padding">User Summary</Typography>
        <Box className="header-btn">
          <Box>
            <Button
              variant="contained"
              className="btn-primary"
              onClick={() => handleClickOpen()}
            >
              Create User
            </Button>
          </Box>
        </Box>
      </Box>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle className="dialog-title">Create User</DialogTitle>
        <DialogContent>
          <Box className="dialog-content">
            <TextField
              autoFocus
              margin="dense"
              id="email"
              label="Email"
              type="text"
              fullWidth
              required
              value={newEmail}
              onChange={(evt) => setNewUserEmail(evt.target.value)}
            />
            <TextField
              autoFocus
              margin="dense"
              id="password"
              label="Password"
              type="text"
              fullWidth
              required
              value={newPassword}
              onChange={(evt) => setNewUserPassword(evt.target.value)}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={() => createNewUser()}>Create User</Button>
        </DialogActions>
      </Dialog>
      {/* Data table */}
      <Box className="content user-table">
        <DataGrid
          experimentalFeatures={{ newEditingApi: true }}
          columnBuffer={columns.length}
          rows={userList}
          rowHeight={170}
          columns={columns}
          pageSize={20}
          rowsPerPageOptions={[20]}
          editMode='row'
          rowModesModel={rowModesModel}
          onRowModesModelChange={handleRowModesModelChange}
          onRowEditStop={handleRowEditStop}
          processRowUpdate={processRowUpdate}
          onProcessRowUpdateError={onProcessRowUpdateError}
        />
      </Box>
      <Dialog open={deleteConfirmationOpen} >
        <DialogTitle>Delete Confirmation</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure about deleting this User?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {loading ?
            <CircularProgress size='2rem' />
            :
            <>
              <Button autoFocus onClick={() => {
                setDeleteConfirmationOpen(false);
              }}>
                Cancel
              </Button>
              <Button onClick={handleDeleteClick(activeUser)}>Ok</Button>
            </>
          }
        </DialogActions>
      </Dialog>
      <Dialog open={saveConfirmationOpen} >
        <DialogTitle>Save Confirmation</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure about saving this User?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {loading ?
            <CircularProgress size='2rem' />
            :
            <>
              <Button autoFocus onClick={() => {
                setSaveConfirmationOpen(false);
              }}>
                Cancel
              </Button>
              <Button onClick={handleSaveClick(activeUser)}>Ok</Button>
            </>
          }
        </DialogActions>
      </Dialog>
      <Snackbar open={flashOpen} autoHideDuration={4000} onClose={() => setFlashOpen(false)}>
        <Alert className="flash" onClose={() => setFlashOpen(false)} severity={flashType}>
          {flashMessage}
        </Alert>
      </Snackbar>
    </>
  )
}
