import { useEffect, useState, useCallback } from 'react';
import {
  Box, Button, Typography,
  Dialog, DialogActions, DialogContent,
  DialogTitle, Stack, Snackbar, Alert,
  DialogContentText, CircularProgress,
  LinearProgress, IconButton, Badge, Autocomplete,
  TextField
} from '@mui/material';
import { useSearchParams} from 'react-router-dom'

import moment from 'moment';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import {
  GridRowModes,
  DataGrid,
  GridActionsCellItem,
  GridRowEditStopReasons,
} from '@mui/x-data-grid';
import { randomId } from '@mui/x-data-grid-generator';

import { useDropzone } from 'react-dropzone';

import { getTenders } from '../../api/tender';
import { getUsers, getUser } from '../../api/user';
import {
  getActions, createUpdateAction,
  deleteAction, uploadActionAttachment,
  getActionAttachment, getActionAttachments, deleteActionAttachment
} from '../../api/action';

export default function ActionSummary() {
  const [actionList, setActionList] = useState([]);
  const [actionFetched, setActionFetched] = useState(false);
  const [rowModesModel, setRowModesModel] = useState({});
  const [tenderList, setTenderList] = useState([]);
  const [tenderOptions, setTenderOptions] = useState([]);
  const [userList, setUserList] = useState([]);
  const [activeActionId, setActiveActionId] = useState('');
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
  const [saveConfirmationOpen, setSaveConfirmationOpen] = useState(false);
  const [uploadOpen, setUploadOpen] = useState(false);
  const [files, setFiles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [flashOpen, setFlashOpen] = useState(false);
  const [flashType, setFlashType] = useState('success');
  const [flashMessage, setFlashMessage] = useState('');
  const [permissionAction, setPermissionAction] = useState(true);

  const [actionAttachments, setActionAttachments] = useState([]);
  const [deleteAttachmentConfirmationOpen, setDeleteAttachmentConfirmationOpen] = useState(false);
  const [activeAttachment, setActiveAttachment] = useState('');

  const [userId, setUserId] = useState('');

  const [filterModel, setFilterModel] = useState({
    items: [],
  });

  const [searchparams] = useSearchParams();

  const onDrop = useCallback(newFiles => {
    actionList.forEach(action => {
      if (action.id === activeActionId) {
        action.files = newFiles;
      }
    });
    setFiles(prevFiles => [...prevFiles, ...newFiles]);
  }, [activeActionId, actionList])

  const { getRootProps, getInputProps } = useDropzone({ onDrop })

  useEffect(() => {
    getAllActions();
    getAllUsers();
    getAllTenders();

    // If either of these are set in the search filter the
    // Table to show the relevant deals
    const actionId = searchparams.get('action')
    const assigneeId = searchparams.get('assignee')
    if (assigneeId) {
      setFilterModel({'items': [
        {
          'columnField': 'user_id',
          'operator': '=',
          'value': assigneeId,
      }
      ]})
    } else if (actionId) {
      setFilterModel({'items': [
        {
          'columnField': 'id',
          'operator': '=',
          'value': actionId,
      }
      ]});
    }

    getUser().then((res) => {
      setUserId(res.user.id);
    });
  }, []);

  /**
   * Get all the actions
   */
  const getAllActions = async () => {
    await getActions().then(async (res) => {
      let actions = res['data'];
      if (!Array.isArray(actions)) {
        actions = [actions];
      }
      if (actionList !== actions) {
        setActionList(actions);
        setActionFetched(true);
      }
    }).catch((error) => {
      setPermissionAction(false);
      setActionFetched(true);
    });
  }

  /**
   * Get all the users and update the user list
   */
  const getAllUsers = async () => {
    await getUsers().then((res) => {
      let users = res['data'];
      // Make sure its an array
      if (!Array.isArray(users)) {
        users = [users];
      }
      if (users !== userList) {
        setUserList(users);
      }
    });
  }

  /**
   * Get all the tenders from the DB and set it to a list
   */
  const getAllTenders = async () => {
    await getTenders().then(async (res) => {
      let tenders = res['data'];
      if (!Array.isArray(tenders)) {
        tenders = [tenders];
      }

      if (tenderList !== tenders) {
        setTenderList(tenders);

        const tenderOptions = tenders.map((tender) => {
          return {'label': tender.client_code + ': ' + tender.project_name, 'id': tender.id};
        })
        tenderOptions.push('{}');
        setTenderOptions(tenderOptions);
      }
    });
  }

  /**
   * Handle adding a new blank object to the action summary table
   */
  const handleAddAction = () => {
    const id = randomId();
    setActionList((oldActionList) => [{
      id,
      object_type: '',
      associated_object_id: '',
      user_id: '',
      description: 'Please update description',
      due_date: new Date(),
      status: 'Draft',
      isNew: true
    }, ...oldActionList]);
    setRowModesModel((oldModel) => ({
      [id]: { mode: GridRowModes.Edit, fieldToFocus: 'object_type' },
      ...oldModel
    }));
  }

  /**
   * handle the stopping of editing the row
   * 
   * @param {object} params The params of the object event
   * @param {DOMEvent} event The event
   */
  const handleRowEditStop = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  /**
   * Handle the change of mode for the rows
   * 
   * @param {object} newRowModesModel The object of the rows
   */
  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  /**
   * Set the row mode to edit
   * 
   * @param {string} id The id of the row / action
   */
  const handleEditClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  /**
   * Set the row mode to view (after saving)
   * 
   * @param {string} id The id of the row / action
   */
  const handleSaveClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  /**
   * Handle deleting an action from the table
   * 
   * @param {string} id The Id of the action
   */
  const handleDeleteClick = (id) => async () => {
    setLoading(true);

    const response = await deleteAction(id);

    setDeleteConfirmationOpen(false);
    setLoading(false);

    if (response.data) {
      setFlashType('success');
      setFlashMessage(response.message);
      setActionList(actionList.filter((action) => action.id !== id));
    } else {
      setFlashType('error');
      setFlashMessage(response.error ?? 'Something went wrong, please try again.');
    }

    setFlashOpen(true);
  };

  /**
   * Cancel editing an action
   * 
   * @param {string} id The Id of the action
   */
  const handleCancelClick = (id) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedAction = actionList.find((action) => action.id === id);
    if (editedAction.isNew) {
      setActionList(actionList.filter((action) => action.id !== id));
    }
  };

  /**
   * The process of saving or updating an action to the backend
   * 
   * @param {Object} newAction The new action to save or update
   * @returns Object The new action that was processed
   */
  const processRowUpdate = async (newAction) => {
    setLoading(true);
    if (!newAction.user_id ||
      (newAction.object_type === 'Tender' && ! newAction.associated_object_id)) {
      setSaveConfirmationOpen(false);
      setLoading(false);
      setFlashType('error');
      if (! newAction.user_id) {
        setFlashMessage('Please fill in an assignee.');
      } else {
        setFlashMessage('Please fill in a Tender');
      }
      setFlashOpen(true);
      return;
    }
    const updatedAction = { ...newAction, isNew: false };

    // send to server to create or update action
    const actionData = {
      'actionInput': {
        'id': newAction.id,
        'object_type': newAction.object_type,
        'associated_object_id': newAction.associated_object_id,
        'user_id': newAction.user_id,
        'description': newAction.description,
        'due_date': newAction.due_date,
        'status': newAction.status,
      }
    };

    const response = await createUpdateAction(actionData);

    setSaveConfirmationOpen(false);
    setLoading(false);

    if (response.data) {
      // Upload the files if action is new (otherwise these will be uploaded adhoc)
      if (newAction.files && newAction.files.length !== 0 && newAction.isNew) {
        uploadData(newAction.files);
      }

      setFlashType('success');
      setFlashMessage(response.message);
      setActionList(actionList.map((action) => (action.id === newAction.id ? updatedAction : action)));
    } else {
      setFlashType('error');
      setFlashMessage(response.error ?? 'Something went wrong, please try again.');
    }

    setFlashOpen(true);
    return updatedAction;
  };

  /**
   * Remove the file from the list of files
   * 
   * @param {object} file 
   */
  const remove = (file) => {
    const newFiles = [...files];
    const activeAction = actionList.find((action) => action.id === activeActionId);
    newFiles.splice(newFiles.indexOf(file), 1);
    setFiles(newFiles);
    activeAction.files = newFiles;
    if (newFiles.length === 0) {
      setUploadOpen(false);
    }
  };

  const acceptedFileItems = files.map((file, i) => (
    <li key={file.path}>
      {file.name} : {file.size} bytes.
      <button className="removeButton" type="button" onClick={() => remove(i)}>Remove</button>
    </li>
  ));

  /**
   * Get an attachment file from the backend (Returned is the blob)
   * and download it or show it to the user (for PDF)
   * 
   * @param {string} fileId The Id of the file
   * @param {object} attachment The attachment object containing the metadata of the file
   */
  function getActionFile(fileId, attachment) {
    getActionAttachment(fileId).then((data) => {
      if (attachment.filetype === 'application/pdf') {
        const url = window.URL.createObjectURL(new Blob([data], { type: 'application/pdf' }));
        const pdfWindow = window.open('/');
        pdfWindow.location.href = url;
      } else {
        const url = window.URL.createObjectURL(new Blob([data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', attachment.filename);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      }
    })
  }

  /**
  * Handle the upload file action. Depending on the action state either the files will
  * be uploaded immediately or the actions will be cached to be uploaded when the action
  * is created (if the action is new)
  * 
  * If the action is new (isNew == true) then the action in the DB hasn't been created.
  * To not create potentially parentless files in S3 we wait to upload the files and give
  * a warning to the user
  */
  function handleUploadingData() {
    const activeAction = actionList.find((action) => action.id === activeActionId)

    if (activeAction.isNew) {
      activeAction.files = files;
      setFlashType('warning');
      setFlashMessage('Files will be uploaded when action is saved');
      setFlashOpen(true);
    } else {
      uploadData().then(() => {
        activeAction.files = [];
      });
    }
    setUploadOpen(false);
  }

  /**
   * Send the attachments to be uploaded to S3 via the backend
   * 
   * @param {Array<fileObject>} filesToUpload 
   */
  async function uploadData(filesToUpload = []) {
    if (filesToUpload.length === 0) {
      filesToUpload = files;
    }
    if (filesToUpload.length !== 0) {
      const formDataObj = new FormData();
      formDataObj.append('actionInput',JSON.stringify({ 'id': activeActionId }))
      for (let i = 0; i < filesToUpload.length; i++) {
        formDataObj.append('files', filesToUpload[i]);
        if (i === filesToUpload.length - 1) {
          uploadActionAttachment(formDataObj).then((res) => {
            return Promise((resolve, reject) => {
              if (res.status === 200) {
                resolve();
              } else {
                reject();
              }
            })
          })
        }
      }
    }
  }

  /**
   * begin the process of deleting an attachment
   * 
   * @param {string} fileId The Id of the file
   */
  function deleteActionFile(fileId) {
    setActiveAttachment(fileId)
    setDeleteAttachmentConfirmationOpen(true);
  }

  /**
   * Handle the confirmation of deleting a file being clicked
   * this will delete the attachment from the DB and S3
   */
  async function handleDeleteAttachmentClick() {
    const fileId = activeAttachment;

    await deleteActionAttachment(fileId).then((data) => {
      const attachment = actionAttachments.find((attachment) => attachment.id === fileId)
      actionAttachments.splice(actionAttachments.indexOf(attachment), 1);
      setActionAttachments(actionAttachments);
      
      setFlashType('success');
      setFlashMessage('File has been deleted');
      setFlashOpen(true);
    }).catch((err) => {
      setFlashType('error');
      setFlashMessage('File was not deleted properly');
      setFlashOpen(true);
    })
    setDeleteAttachmentConfirmationOpen(false)
  }

  /**
   * Handle the closing of the review modal
   */
  function handleClose() {
    setUploadOpen(false);
  }

  /**
   * Swap the filter to show all the actions assigned to that user
   */
  function showAssignedActions() {
    setFilterModel({
      'items': [{
          'columnField': 'user_id',
          'operator': '=',
          'value': userId,
      }]
    })
  }

  /**
   * Reset the filter for the action table
   */
  function resetFilters() {
    setFilterModel({
      'items': []
    })
  }

  const columns = [
    {
      field: 'id',
    },
    {
      field: 'object_type',
      headerName: 'Type',
      width: 190,
      flex: 0.3,
      editable: true,
      type: 'singleSelect',
      valueOptions: ['Tender', 'Non-Tender'],
    },
    {
      field: 'associated_object_id',
      headerName: 'Action Item',
      width: 190,
      flex: 0.8,
      renderCell: (params) => {
        const tenderId = params.row.associated_object_id;
        const selectedTender = tenderList.find(tender => tender.id === tenderId);
        let tenderLabel = {}
        if (selectedTender) {
          tenderLabel = {
            'label': selectedTender.client_code + ': ' + selectedTender.project_name,
            'id': selectedTender.id
          };
        }
        return (
          <Autocomplete
            value={tenderLabel}
            disablePortal
            isOptionEqualToValue={(option, value) => {
              if (Object.keys(option).length === 0) {
                return false;
              }
              return option.id === value.id;
            }}
            getOptionLabel={(tender) => {
              if (tender.label) {
                return tender.label;
              }
              return '';
            }}
            options={tenderOptions}
            sx={{ width: 300 }}
            renderInput={(params) => {
              params.inputProps.onKeyDown = handleKeyDown;
              return <TextField {...params} />
            }}
            onChange={(event, value) => {
              setRowModesModel({ ...rowModesModel, [params.id]: { mode: GridRowModes.Edit } });
              if (value) {
                params.row.associated_object_id = value.id;
              } else {
                params.row.associated_object_id = '';
              }
            }}
          />
        );
      }
    },
    {
      field: 'user_id',
      headerName: 'Assignee',
      width: 190,
      flex: 0.5,
      editable: true,
      type: 'singleSelect',
      valueFormatter: (params) => {
        if (params.value == null) {
          return '';
        }
        const userId = params.value;
        const selectedUser = userList.find(user => user.id === userId);
        if (!selectedUser) {
          return '';
        }
        return selectedUser.name;
      },
      valueOptions: userList.map(user => user.id),
    },
    {
      field: 'description',
      headerName: 'Description',
      width: 340,
      flex: 0.8,
      editable: true,
    },
    {
      field: 'due_date',
      headerName: 'Due Date',
      type: 'date',
      width: 120,
      flex: 0.2,
      editable: true,
      valueFormatter: (params) => {
        return moment(params.value).format('DD/MM/YYYY');
      }
    },
    {
      field: 'status',
      headerName: 'Status',
      width: 80,
      flex: 0.2,
      editable: true,
      type: 'singleSelect',
      valueOptions: ['Draft', 'WIP', 'Completed'],
    },
    {
      field: 'attachments',
      type: 'actions',
      headerName: 'Attachments',
      width: 120,
      cellClassName: 'actions',
      getActions: ({ id, row }) => {
        return [
          <GridActionsCellItem
            icon={
              <>
                {row.fileCount !== '0' ? 
                  <Badge badgeContent={row.fileCount} color="error">
                    <UploadFileIcon />
                  </Badge>
                : 
                  <UploadFileIcon />
                }
              </>
            }
            label='upload'
            sx={{
              color: 'primary.main',
            }}
            onClick={() => {
              setActiveActionId(id);
              if (row.files) {
                setFiles(row.files);
              } else {
                setFiles([]);
              }

              if (row.isNew) {
                setActionAttachments([]);
                setUploadOpen(true);
              } else {
                getActionAttachments(id).then((res) => {
                  setActionAttachments(res['data']);
                  setUploadOpen(true);
                })
              }
            }}
          />
        ]
      }
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      width: 100,
      cellClassName: 'actions',
      getActions: ({ id, row }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label='Save'
              sx={{
                color: 'primary.main',
              }}
              onClick={() => {
                setActiveActionId(id);
                setSaveConfirmationOpen(true);
              }}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label='Cancel'
              className='textPrimary'
              onClick={handleCancelClick(id)}
              color='inherit'
            />,
          ];
        }

        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label='Edit'
            className='textPrimary'
            onClick={handleEditClick(id)}
            color='inherit'
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label='Delete'
            onClick={() => {
              setActiveActionId(id);
              setDeleteConfirmationOpen(true);
            }}
            color='inherit'
          />,
        ];
      },
    },
  ];

  /**
   * Override the space keydown to prevent switching away from
   * the 
   * 
   * @param {DOMEvent} event The dom event triggered
   */
  const handleKeyDown = event => {
    switch (event.key) {
      case ' ': {
        event.preventDefault();
        event.stopPropagation();
        event.target.value = event.target.value + ' ';
        break;
      }
      default:
    }
  };

  if (!actionFetched) {
    return <LinearProgress />;
  }

  return (
    <>
      <Box className="title-box">
        <Typography className="title text user-title text-padding">Action Summary</Typography>
      </Box>
      <Box className="content">
        <Stack direction="row" className="action-actions" spacing={1}>
        <Button
            className="btn-primary"
            variant="contained"
            onClick={showAssignedActions}
          >
            Show My Actions
          </Button>
          <Button
            variant="outlined"
            color="error"
            disabled={filterModel.items.length === 0}
            onClick={resetFilters}
          >
            Reset Filters
          </Button>
          <Box className="stack-divider"/>
          <Button
            className="btn-primary"
            variant="contained"
            startIcon={<AddIcon />}
            onClick={handleAddAction}
          >
            Add Action
          </Button>
        </Stack>
        <Box className="action-table table-data">
          <DataGrid
            experimentalFeatures={{ newEditingApi: true }}
            columnBuffer={columns.length}
            rows={actionList}
            columns={columns}
            editMode='row'
            rowModesModel={rowModesModel}
            onRowModesModelChange={handleRowModesModelChange}
            onRowEditStop={handleRowEditStop}
            processRowUpdate={processRowUpdate}
            onProcessRowUpdateError={(error) => {
              setFlashType('error');
              setFlashMessage(error);
              setFlashOpen(true);
            }}
            components={{
              NoRowsOverlay: () => (
                <Stack height="100%" alignItems="center" justifyContent="center">
                  {permissionAction ? (
                    <p>No Rows</p>
                  ) : (
                    <p>Unauthorized to see Actions</p>
                  )}
                </Stack>
              )
            }}
            filterModel={filterModel}
            onFilterModelChange={(newFilterModel) => {
              window.history.pushState(null, '', '?');
              setFilterModel(newFilterModel)
            }}
            initialState={{
              columns: {
                columnVisibilityModel: {
                  id: false,
                },
              },
            }}
          />
        </Box>
      </Box>
      <Dialog open={deleteConfirmationOpen} >
        <DialogTitle>Delete Confirmation</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure about deleting this action?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {loading ?
            <CircularProgress size='2rem' />
            :
            <>
              <Button autoFocus onClick={() => {
                setDeleteConfirmationOpen(false);
              }}>
                Cancel
              </Button>
              <Button onClick={handleDeleteClick(activeActionId)}>Ok</Button>
            </>
          }
        </DialogActions>
      </Dialog>
      <Dialog open={saveConfirmationOpen} >
        <DialogTitle>Save Confirmation</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure about saving this action?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {loading ?
            <CircularProgress size='2rem' />
            :
            <>
              <Button autoFocus onClick={() => {
                setSaveConfirmationOpen(false);
              }}>
                Cancel
              </Button>
              <Button onClick={handleSaveClick(activeActionId)}>Ok</Button>
            </>
          }
        </DialogActions>
      </Dialog>
      <Dialog open={uploadOpen} >
        <DialogTitle>Add Attachments</DialogTitle>
        <DialogContent>
          <Box className="file-list">
            <h4>Uploaded Files:</h4>
            <ul className="uploaded-files-list">
              {actionAttachments.map(attachment =>
                <li
                  key={attachment.filename}
                  value={attachment.filename}
                >
                  <button
                    className="file-link"
                    onClick={() => getActionFile(attachment.id, attachment)}
                  >
                    {attachment.filename}
                  </button>
                  <IconButton
                    color="error"
                    onClick={() => {
                      deleteActionFile(attachment.id)
                    }}
                  >
                    <CancelIcon />
                  </IconButton>
                </li>
              )}
            </ul>
          </Box>
          <Box className="fileupload">
            <div {...getRootProps({ className: "dropzone" })}>
              <input {...getInputProps()} />
              <p>Drag & Drop files here, or click to select.</p>
            </div>
          </Box>
          <Box className="file-list">
            <h4>Files Attached:</h4>
            {acceptedFileItems.length ?
              <ul>{acceptedFileItems}</ul>
              : <p>There are currently no files attached.</p>
            }
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={(event) => handleUploadingData()}>Upload Data</Button>
        </DialogActions>
      </Dialog>
      <Dialog open={deleteAttachmentConfirmationOpen} >
        <DialogTitle>Delete Confirmation</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure about deleting this action attachment?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {loading ?
            <CircularProgress size='2rem' />
            :
            <>
              <Button autoFocus onClick={() => {
                setDeleteAttachmentConfirmationOpen(false);
              }}>
                Cancel
              </Button>
              <Button onClick={() => {
                handleDeleteAttachmentClick()
              }}>Ok</Button>
            </>
          }
        </DialogActions>
      </Dialog>
      <Snackbar open={flashOpen} autoHideDuration={4000} onClose={() => setFlashOpen(false)}>
        <Alert className="flash" onClose={() => setFlashOpen(false)} severity={flashType}>
          {flashMessage}
        </Alert>
      </Snackbar>
    </>
  )
}
