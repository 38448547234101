import { createSlice } from '@reduxjs/toolkit';

const initState = {
    authenticated: false,
    fetching: true,
    errors: false,
    user: {},
}

const userSlice = createSlice({
    name: 'user',
    initialState: initState,
    reducers: {
        getUserPending: ( state ) => {
            // State updating code goes here. 
            state = initState;
        },
        getUserSuccess: ( state, action ) => {
            state.authenticated = true;
            state.fetching = false;
            state.user = action.payload
        },
        getUserError: ( state ) => {
            state.authenticated = true;
            state.fetching = false;
            state.errors = true;
        }
    },
    extraReducers: {},
})

export const { getUserPending, getUserSuccess, getUserError } = userSlice.actions
export default userSlice.reducer