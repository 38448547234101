import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getUserSuccess, getUserPending, getUserError } from '../../redux/features/user/userSlice';
import { getUser } from '../../api/user';
import { Box } from '@mui/system';
import axios from 'axios';

// Component Imports:
import CategoryCard from './CategoryCard';
import WelcomeCard from './WelcomeField';
import Footer from '../HelperComponents/footer';

// Asset Imports:
import TenderWorkflowHero from '../../resources/elements/Tender-Workflows.png';
import UserHero from '../../resources/elements/Utility-Forms.png';

export default function LandingPage(props) {
  const dispatch = useDispatch();
  // Check if the user has been properly authenticated and conditionally render:
  const authenticated = useSelector(state => state.user.authenticated);
  // GET /api/user/state to check authentication then dispatch required Redux Methods:
  useEffect(() => {
    checkAuthenticated().then(authenticated => {
      if (! authenticated) {
        window.location.href = '/';
      } else {
        // If the user has had a previous placement, redirect them to the origin url
        const originUrl = localStorage.getItem('origin_url');
        if (originUrl !== undefined && originUrl !== null) {
          localStorage.removeItem('origin_url');
          window.location.href = originUrl;
        }
      }
    });
  })

  /**
   * Fetch a new user state from the Backend
   */
  const checkAuthenticated = async () => {
    // Set pending action to pending while waiting on request.
    dispatch(getUserPending());
    return await getUser().then((res) => {
      if (res.authenticated) {
        // If the user is successfully authenticated via passport, set the success action to the users credentials:
        dispatch(getUserSuccess(res.user));
        return true;
      } else {
        // If an error occurs set the user action to an error state:
        dispatch(getUserError());
        return false;
      }
    });
  }

  return (
    <div className="dashboard">
      {authenticated && <WelcomeCard />}
      <Box className="dashboard-cards">
        {authenticated && <CategoryCard type="tender" title="Tender Summary" asset={TenderWorkflowHero} />}
        {authenticated && <CategoryCard type="action" title="Action Summary" asset={UserHero} />}
        {authenticated && <CategoryCard type="users" title="Users" asset={UserHero} />}
      </Box>
      <Footer />
    </div>
  );
}
