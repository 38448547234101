import axios from 'axios';

/**
 * This file contains all of the API calls related 
 * specifically to the Action Workflow
 */

/**
 * Get all the action
 * @returns Array[Object]
 */
export function getActions() {
  return axios.get('/api/actions')
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      console.log(err);
    });
}

/**
 * Get actions for start up
 * @param {String} startUpId The Id of the desired start up
 * @returns Array[Object]
 */
export function getActionsForStartUp(startUpId) {
  const paramData = {
    params: {
      startUpId
    }
  };
  return axios.get('/api/actions', paramData)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      console.log(err);
    });
}

/**
 * Save actions for start up
 * 
 * @param {object} actionData The data to be created/updated
 * @returns Object The created/updated action
 */
export function saveActionsForStartUp(actionData) {
  return axios.post('/api/actions/start-up', actionData)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      console.log(err);
    });
}

/**
 * Create or update an action
 * 
 * @param {object} actionData The data to be created/updated
 * @returns Object The created/updated action
 */
export function createUpdateAction(actionData) {
  return axios.post('/api/actions', actionData)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      console.log(err);
    });
}

/**
 * Create or update an action
 * 
 * @param {object} actionData The data to be created/updated
 * @returns Object The created/updated action
 */
export function uploadActionAttachment(actionData) {
  return axios.post('/api/actions/attachments', actionData)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      console.log(err);
    });
}

/**
 * Create or update an action
 * 
 * @param {object} actionData The data to be created/updated
 * @returns Object The created/updated action
 */
export function getActionAttachments(actionId) {
  return axios.get('/api/actions/attachments/' + actionId)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      console.log(err);
    });
}

/**
 * Create or update an action
 * 
 * @param {object} actionData The data to be created/updated
 * @returns Object The created/updated action
 */
export function getActionAttachment(actionId) {
  // Return type must be blob
  return axios.get('/api/actions/attachments/file/' + actionId, {responseType: 'blob'})
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      console.log(err);
    });
}

/**
 * Create or update an action
 * 
 * @param {object} actionData The data to be created/updated
 * @returns Object The created/updated action
 */
export function deleteActionAttachment(actionId) {
  return axios.delete('/api/actions/attachments/file/' + actionId)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      console.log(err);
    });
}

/**
 * Delete a specific action
 * 
 * @param {string} actionId The Id of the action to be deleted
 * @returns The deleted action
 */
export const deleteAction = (actionId) => {
  return axios.delete('/api/actions/' + actionId)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      console.log(err);
    });
}
