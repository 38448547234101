
import React from 'react';
import { FormControl, FormLabel, TextField, FormHelperText } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import 'dayjs/locale/en-au';

/**
 * The Common Datapicker component
 */
class FormDatePicker extends React.Component {

  /**
   * The on change function to execute when the date picker changes
   * 
   * @param {string} value The value of the new date picker
   */
  onChange = (value) => {
    // Call the parent callback function
    this.props.parentCallback(value, this.props.field);
  }

  /**
   * React seems to have an inherent bug where some registers of backspace
   * don't register. Therefore need to add a handler for backspace press
   * to push through the event anyway
   * 
   * @param {object} event The dom object event
   */
  handleBackspace = (event) => {
    if (event.keyCode === 8) {
      this.onChange(event.target.value);
    }
  };

  render() {
    return (
      <FormControl className="form-label">
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-au">
          <FormLabel id={this.props.field.id}>{this.props.field.label}</FormLabel>

          <DatePicker
            value={this.props.dates[this.props.field.id] ? this.props.dates[this.props.field.id] : null }
            onChange={(newValue) => {
              this.onChange(newValue)
            }}
            inputFormat="DD/MM/YYYY"
            renderInput={(props) => {
              let inputProps = props.inputProps;
              inputProps = { ...inputProps, className: "date-input" };
              props.inputProps = inputProps;
              return <TextField 
                        labelId={this.props.field.id}
                        name={this.props.field.id}
                        {...props}
                        size="small"
                        onKeyUp={this.handleBackspace}
                        helperText={null}
                        error={this.props.field.isError}
                      />
            }}
          />
        </LocalizationProvider>
        {this.props.field.isError &&
          <FormHelperText className="error">{this.props.field.errorText}</FormHelperText>
        }
      </FormControl>
    );
  }
}
export default FormDatePicker;
