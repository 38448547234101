import React, { useState, useEffect, useReducer } from 'react';
import {
  FormControl, Button, Box, FormLabel,
  Select, MenuItem, Grid, Typography,
  Snackbar, Alert
} from '@mui/material';

// Import API
import { createDataEntry } from '../../api/tender/index.js';

// Import Common fields
import getFieldHTML from './getFieldHtml.js';

export default function Data_Entry(props) {
  const dataEntryFieldsBase = require('../../resources/data/dataentry.json');

  const [loaded, setLoaded] = useState(false);

  const [dataEntryFormInput, setdataEntryFormInput] = useReducer(
    (state, newState) => {
      const combinedState = { ...state, ...newState };
      return (combinedState);
    },
  );

  const [dates, setDates] = useState({});
  const [dataEntryFields, setdataEntryFields] = useState([]);
  const [submissionStatus, setSubmissionStatus] = useState('na');

  const [flashOpen, setFlashOpen] = useState(false);
  const [flashType, setFlashType] = useState('success');
  const [flashMessage, setFlashMessage] = useState('');

  const mandatoryTriggerFields = ['submitted-date', 'submitted-value'];
  const mandatoryFields = ['proposed-margin', 'gfaM2', 'rate-per'];
  const exemptMandatoryFields = ['resubmitted-value', 're-rate-per'];

  useEffect(() => {
    setLoaded(false);
    // Do All loading within setlLoaded
    loadDataEntry();
    setLoaded(true);
  }, [props]);

  /**
   * Change the date in the date field
   * 
   * @param {Date} newDate The date of the changed field
   * @param {Object} field The field that date is being changed for
   */
  function handleDateChange(newDate, field) {
    // Add required label
    if (mandatoryTriggerFields.includes(field.id)) {
      dataEntryFields.forEach(dataEntryField => {
        if (dataEntryField.type === 'group') {
          dataEntryField.values.forEach(dataEntryField => {
            // Check if field needs to be made mandatoy
            if (mandatoryFields.includes(dataEntryField['id']) && ! dataEntryField.label.includes('*')) {
              dataEntryField.label = dataEntryField.label + '*';
            }
            // Check if field has content and was set to an error (unsetting it)
            if (dataEntryField.isError && field.id === dataEntryField['id'] && newDate !== '') {
              dataEntryField.isError = false;
              dataEntryField.errorText = '';
            }
          });
        }
      });
      setdataEntryFields(previousFields => dataEntryFields);
    }
    setDates({ ...dates, [field.id]: newDate != null ? newDate.toJSON() : ''});
  }

  /**
   * Handle an input change and update the field value
   * 
   * @param {DOMEvent} event The onChange event
   */
  function handleInput(event) {
    const name = event.target.name;
    const key = event.target.value;
    updateFieldValue(name, key);
  }
  
  /**
   *  Update the status of the GoNoGo submission
   * 
   * @param {DOMEvent} event The onChange event
   */
  function handleStatus(event) {
    const key = event.target.value;
    setSubmissionStatus(key);
  }

  /**
   * Set a field to mandatory
   * 
   * @param {object} dataEntryField 
   * @param {object} dataEntryFormInput 
   * @returns whether a field was set to mandatory
   */
  function setToMandatory(dataEntryField, dataEntryFormInput) {
    let encounterdMandatoryField = false;
    const fieldToSet = dataEntryFormInput[dataEntryField['id']];
    // Handle date field seperately
    if (dataEntryField.type === 'date') {
      if (! dates[dataEntryField['id']]) {
        dataEntryField.isError = true;
        dataEntryField.errorText = 'Mandatory';
        encounterdMandatoryField = true;
      }
    } else if (checkFieldHasNoValue(fieldToSet) && ! exemptMandatoryFields.includes(dataEntryField['id'])) {
      dataEntryField.isError = true;
      dataEntryField.errorText = 'Mandatory';
      encounterdMandatoryField = true;
    }
    return encounterdMandatoryField;
  }

  /**
   * Check if all fields are filled in if the status has been set to completed
   * 
   * @returns bool: whether all fields are filled in
   */
  function checkSubmissionStatus() {
    let completedAllFields = true;
    if (submissionStatus === 'completed') {
      dataEntryFields.forEach(dataEntryField => {
        let setFieldsToFalse
        if (dataEntryField.type === 'group') {
          dataEntryField.values.forEach(dataEntryField => {
            // Check the fields if they need to be set
            // Date is handled seperately so need to check byitself
            setFieldsToFalse = setToMandatory(dataEntryField, dataEntryFormInput);
          });
        } else {
          setFieldsToFalse = setToMandatory(dataEntryField, dataEntryFormInput);
        }
        if (setFieldsToFalse) {
          completedAllFields = false;
        }
      });
      setdataEntryFields(previousFields => dataEntryFields);
    }
    
    return completedAllFields;
  }

  /**
   * Checks to see if the field has a value
   * either null or an empty key field
   * 
   * @param {object} dataEntryInput 
   * @returns if the field has no value
   */
  function checkFieldHasNoValue(dataEntryInput) {
    return ! dataEntryInput || dataEntryInput?.key === '';
  }

  /**
   * Check all mandatory fields to see if they need to be filled out
   * 
   * @returns If any mandatory fields are missing
   */
  function checkSubmittedMandatoryFields() {
    let missingMandatoryFields = false;
    mandatoryFields.forEach(mandatoryField => {
      if (checkFieldHasNoValue(dataEntryFormInput[mandatoryField])) {
        missingMandatoryFields = true;
      }
    });
    return missingMandatoryFields;
  }

  /**
   * Set a mandatory field to have an error if it doesn't pass
   * validation
   * 
   * @param {object} dataEntryField The field to check
   * @returns The modified field
   */
  function setSubmittedMandatoryFields(dataEntryField) {
    if (checkFieldHasNoValue(dataEntryFormInput[dataEntryField['id']])) {
      dataEntryField.isError = true;
      dataEntryField.errorText = 'Mandatory';
    }
    return dataEntryField;
  }

  /**
   * Check if any of the calculated mandatory fields have been filled out
   * 
   * if submitted date or submitted value have been filled out there is a list of mandatory
   * fields (based on them) that need to be calculated as well
   * 
   * @returns Whether all fields that have been made mandatory have been submitted
   */
  function checkCalculationsDone() {
    let completedAllFields = true;
    // Check if these two fields have been submitted
    if ((dataEntryFormInput['submitted-date'] || dataEntryFormInput['submitted-value']) && checkSubmittedMandatoryFields()) {
      dataEntryFields.forEach(dataEntryField => {
        if (dataEntryField.type === 'group') {
          dataEntryField.values.forEach(dataEntryField => {
            if (mandatoryFields.includes(dataEntryField['id'])) {
              dataEntryField = setSubmittedMandatoryFields(dataEntryField)
              completedAllFields = false;
            }
          });
        }
      });
      setdataEntryFields(previousFields => dataEntryFields);
    }

    return completedAllFields;
  }

  /**
   * Format the form information and submit to the backend
   * 
   * @param {object} event 
   */
  function handleSubmit(event) {
    event.preventDefault();

    const submittedCalculations = checkCalculationsDone();
    if (! submittedCalculations) {
      setFlashType('error');
      setFlashMessage('Please fill in all mandatory fields');
      setFlashOpen(true);
      // Exit out of the process
      return;
    }
    
    const validSubmission = checkSubmissionStatus();
    if (! validSubmission) {
      setFlashType('error');
      setFlashMessage('Please fill in all fields');
      setFlashOpen(true);
      return;
    }

    // Update the tender information
    props.tenderSubmit();

    const datesData = dates;
    for (const date in datesData) {
      dataEntryFormInput[date] = datesData[date];
    }

    const dataEntryData = {
      'dataEntryFormInput': {
        'tender_id': props.tenderInformation.id,
        'status': submissionStatus,
        'fields': dataEntryFormInput
      }
    };
    createDataEntry(dataEntryData);
    window.location.reload();
  }

  /**
   * unset any errors if a value has been filled in to indicate that
   * the field is now valid
   * 
   * @param {string} name The name of the newly changed field
   * @param {object} key The key value pair of the change value
   * @param {object} dataEntryField The object representation of the field
   */
  function unsetErrorFields(name, key, dataEntryField) {
    if (dataEntryField.isError && (name === dataEntryField['id'] && key)) {
      dataEntryField.isError = false;
      dataEntryField.errorText = '';
    }
  }

  /**
   * Check to see if the mandatory fields are filled in if the trigger fields are filled in
   * 
   * @param {string} name The name of the newly changed field
   * @param {object} key The key value pair of the change value
   */
  function checkMandatoryFieldsFilledIn(name, key) {
    let triggerFieldsFilledIn = false;
    // See if the trigger fields are filled in
    if (dataEntryFormInput) {
      if (name === 'submitted-value' && dates['submitted-date']) {
        triggerFieldsFilledIn = true;
      } else if (name === 'submitted-date' && ! checkFieldHasNoValue(dataEntryFormInput['submitted-value'])) {
        triggerFieldsFilledIn = true;
      }
    } 
    if (key && mandatoryTriggerFields.includes(name)) {
      triggerFieldsFilledIn = true;
    }

    dataEntryFields.forEach(dataEntryField => {
      if (dataEntryField.type === 'group') {
        dataEntryField.values.forEach(dataEntryField => {
          // Add required label
          if (mandatoryFields.includes(dataEntryField['id'])) {
            // Check if field needs to have a mandatory indicator (*)
            if (triggerFieldsFilledIn && ! dataEntryField.label.includes('*')) {
                dataEntryField.label = dataEntryField.label + '*';
            } else if (! triggerFieldsFilledIn) {
              dataEntryField.label = dataEntryField.label.replace('*', '');
            }
          }
        });
      }
    });
    setdataEntryFields(previousFields => dataEntryFields);
  }

  /**
   * Check if the field requirements have been met and if so unset the fields
   * 
   * @param {string} name The name of the field
   * @param {string} key The value of the field
   */
  function checkFieldReq(name, key) {
    dataEntryFields.forEach(dataEntryField => {
      if (dataEntryField.type === 'group') {
        dataEntryField.values.forEach(dataEntryField => {
          unsetErrorFields(name, key, dataEntryField);
          return;
        });
      } else {
        unsetErrorFields(name, key, dataEntryField);
        return;
      }
    });
  }

  /**
   * Update the field value in the form input
   * 
   * And check if the mandatory settings need to be changed
   * 
   * @param {string} name The name of the field
   * @param {string} key The key of the field
   */
  function updateFieldValue(name, key) {
    let value = 0;

    setdataEntryFormInput({ [name]: { 'key': key, 'value': value } });
    if (mandatoryTriggerFields.includes(name)) {
      checkMandatoryFieldsFilledIn(name, key);
    } else if (mandatoryFields.includes(name) || submissionStatus === 'completed') {
      checkFieldReq(name, key);
    }
  }

  /**
   * Load in all the Data Entry information
   * and update all the fields
   */
  function loadDataEntry() {
    // Deep copy the base attributes
    const dataEntryFieldsBaseData = JSON.parse(JSON.stringify(dataEntryFieldsBase))['data'];
    setdataEntryFields(dataEntryFieldsBaseData);
    if (props.DataEntryForm.fields && props.tenderInformation.id) {
      const dataEntrySubmittedFields = JSON.parse(props.DataEntryForm.fields);

      dataEntryFieldsBaseData.forEach(element => {
        if (element.type === 'group') {
          element.values.forEach(subfield => {
            if (subfield.type === 'date') {
              setDates(previousInputs => ({ ...previousInputs, [subfield.id]: dataEntrySubmittedFields[subfield.id] }));
            } else if (Object.keys(dataEntrySubmittedFields).includes(subfield.id)) {
              updateFieldValue(subfield.id, dataEntrySubmittedFields[subfield.id]['key']);
              subfield.selectedValue = dataEntrySubmittedFields[subfield.id];
            }
          })
        } else if (element.type === 'date') {
          setDates(previousInputs => ({ ...previousInputs, [element.id]: dataEntrySubmittedFields[element.id] }));
        } else {
          if (Object.keys(dataEntrySubmittedFields).includes(element.id)) {
            updateFieldValue(element.id, dataEntrySubmittedFields[element.id]['key']);
            element.selectedValue = dataEntrySubmittedFields[element.id];
          }
        }
      });
      setdataEntryFields(dataEntryFieldsBaseData);
      setSubmissionStatus(props.DataEntryForm.status);
    } else {
      setdataEntryFields(dataEntryFieldsBaseData);
    }
  }

  /**
   * Render all of the Date Entry fields into HTML
   * 
   * @returns Array[HTMLObject] THe rendering of all the fields
   */
  function renderDataEntryItems() {
    return dataEntryFields.map(field =>
      getFieldHTML(field, handleInput, handleDateChange, dates)
    );
  }

  return (
    <>
      <form onSubmit={handleSubmit} className={"step-form".concat(loaded ? '' : ' hidden')}>
        <Typography className="secondary-title text">Data Entry Information</Typography>
        <Grid container spacing={2} className="field-grid">
          {renderDataEntryItems()}
        </Grid>
        <Box className="step-status">
          <FormControl className="form-label status-label">
            <FormLabel id="status">Date Entry Status</FormLabel>
            <Select
              labelId="status"
              name="status"
              onChange={handleStatus}
              value={submissionStatus}
            >
              <MenuItem value="na">
                <em>NA</em>
              </MenuItem>
              <MenuItem value="wip">
                <em>WIP</em>
              </MenuItem>
              <MenuItem value="completed">
                <em>Completed</em>
              </MenuItem>
            </Select>
          </FormControl>
        </Box>
        <Box className="step-submission">
          <Button
            className="btn-primary"
            type="submit"
            variant="contained"
          >
            Submit
          </Button>
        </Box>
      </form>
      <Snackbar open={flashOpen} autoHideDuration={4000} onClose={() => setFlashOpen(false)}>
        <Alert className="flash" onClose={() => setFlashOpen(false)} severity={flashType}>
          {flashMessage}
        </Alert>
      </Snackbar>
    </>
  )
}
